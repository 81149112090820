<template>
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 0.721688C10.0235 0.275106 10.9765 0.275106 11.75 0.721688L17.9103 4.27831C18.6838 4.72489 19.1603 5.55021 19.1603 6.44337V13.5566C19.1603 14.4498 18.6838 15.2751 17.9103 15.7217L11.75 19.2783C10.9765 19.7249 10.0235 19.7249 9.25 19.2783L3.08975 15.7217C2.31624 15.2751 1.83975 14.4498 1.83975 13.5566V6.44337C1.83975 5.55021 2.31624 4.72489 3.08975 4.27831L9.25 0.721688Z"
      fill="white"
    />
    <path
      d="M9.66671 1.31413C10.1824 1.01641 10.8177 1.01641 11.3334 1.31413L17.6053 4.93522C18.1209 5.23294 18.4386 5.78315 18.4386 6.37859V13.6208C18.4386 14.2162 18.1209 14.7664 17.6053 15.0641L11.3334 18.6852C10.8177 18.9829 10.1824 18.9829 9.66671 18.6852L3.39481 15.0641C2.87914 14.7664 2.56147 14.2162 2.56147 13.6208V6.37859C2.56147 5.78315 2.87914 5.23294 3.39481 4.93522L9.66671 1.31413Z"
      fill="url(#paint0_linear_1577_1370)"
    />
    <path
      d="M7.422 14.5773L8.24867 11.0353L5.5 8.654L9.12067 8.34067L10.5387 5L11.9567 8.34L15.5767 8.65333L12.8287 11.0347L13.6553 14.5767L10.5387 12.6967L7.422 14.5773Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1577_1370"
        x1="3.00006"
        y1="19.1663"
        x2="19.6666"
        y2="0.832883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9A9A9A" />
        <stop offset="0.493213" stop-color="#2B6FA1" />
        <stop offset="1" stop-color="#AA9A9A" />
      </linearGradient>
    </defs>
  </svg>
</template>
