<template>
  <CheckPointsRewards v-if="currentPage === 'CheckPointsRewards'" @nextPage="handleNextPage" />
  <SMSVerification v-if="currentPage === 'SMSVerification'" @nextPage="handleNextPage" />
</template>

<script setup>
import { ref } from 'vue'
import CheckPointsRewards from './CheckPointsRewards.vue'
import SMSVerification from './SMSVerification.vue'

const currentPage = ref('CheckPointsRewards')

const handleNextPage = (val) => {
  currentPage.value = val
}
</script>
