<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="white"
    class="bi bi-exclamation"
    viewBox="-8 -8 32 32"
  >
    <circle cx="8" cy="9" r="15" fill="#FF0000" fill-opacity="0.3" />
    <circle cx="8" cy="9" r="13" fill="#FF0000" />
    <path
      d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0z"
    />
  </svg>
</template>
