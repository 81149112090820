<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <MobileLayout class="margin-0" :title="store.brandName">
    <div class="container mt-6 mb-12">
      <template v-if="customer.fetching.value">
        <h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">Loading...</h1>
      </template>
      <template v-else-if="!customer.fetching.value">
        <FlexCol class="container min-h-[90vh] overflow-y-auto">
          <FlexCol class="justify-between" style="min-height: inherit">
            <FlexRow class="my-4">
              <FlexCol class="w-full">
                <template v-if="isRegistered">
                  <h1 class="title-3xl">Hi, {{ name }}</h1>
                  <p class="text-sm text-gray-400">Phone no: {{ phoneNumber }}</p>
                  <Card
                    class="w-full my-1.5"
                    style="background: linear-gradient(220deg, #f00 0%, #900 100%)"
                  >
                    <FlexRow>
                      <FlexCol class="basis-8/12">
                        <p class="text-sm text-white">You have</p>
                        <h2 class="mt-2 mb-1 font-poppinsBold text-white">
                          <span class="text-3xl">{{ userPoints.toLocaleString('en-US') }}</span
                          >&nbsp;
                          <span>Points</span>
                        </h2>
                      </FlexCol>
                      <FlexCol class="basis-1/12 justify-center">
                        <MembershipStar style="height: 20px; width: 20px" />
                      </FlexCol>
                      <FlexCol class="basis-3/12 justify-center">
                        <p class="font-poppinsBold text-white">{{ membershipTitle }}</p>
                      </FlexCol>
                    </FlexRow>
                    <FlexRow>
                      <p class="mt-4 text-sm text-white leading-tight font-medium">
                        Earn more points and Enjoy exclusive benefits
                      </p>
                    </FlexRow>
                  </Card>
                </template>
                <template v-else>
                  <h1 class="title-3xl">Check your rewards:</h1>
                </template>
                <RewardsPanel
                  :rewards="store.rewards"
                  :is-registered="true"
                  :user-points="userPoints"
                />
              </FlexCol>
            </FlexRow>
          </FlexCol>
        </FlexCol>
      </template>
    </div>
    <template #footer>
      <MobileFooter :active="'Rewards'" />
    </template>
  </MobileLayout>
</template>
<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Card from '@/components/uielements/Card/Card.vue'
import RewardsPanel from '@/components/reusable/RewardsPanel.vue'
import MembershipStar from '@/components/icons/IconMembershipStar.vue'
import { useQuery } from '@urql/vue'
import { usePhoneStore } from '../../stores/store'
import { useBrandStore } from '../../stores/store'
import { computed, ref, watch } from 'vue'
import { GET_CUSTOMER_POINTS } from '@/constants/graphql'

const name = ref('')
const userPoints = ref('')
const membershipTitle = ref('')
const isRegistered = ref(true)
const phoneStore = usePhoneStore()
const store = useBrandStore()

const customer = useQuery({
  query: GET_CUSTOMER_POINTS,
  variables: {
    phoneNumber:
      phoneStore.trunkPrefix + phoneStore.phoneNumber !== '' &&
      phoneStore.trunkPrefix + phoneStore.phoneNumber !== null
        ? phoneStore.trunkPrefix + phoneStore.phoneNumber
        : phoneStore.phoneNumberWithPrefix,
    slug: store.slugUrl
  }
})

watch(customer.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    name.value = customer.data.value.slAppCustomer.name
    membershipTitle.value = customer.data.value.slAppCustomer.membership.title

    customer.data.value.slAppCustomer.brands.forEach((i) => {
      userPoints.value = i.totalAvailablePoints
    })
  }
})

const phoneNumber = computed(() => {
  const phoneNum = phoneStore.trunkPrefix + phoneStore.phoneNumber

  if (phoneNum !== '' && phoneNum !== null) {
    return phoneNum
  } else {
    return phoneStore.phoneNumberWithPrefix
  }
})
</script>
