<template>
  <template v-if="store.brandName === ''">
    <template v-if="store.brandQueryFetching">
      <FlexRow class="justify-center">
        <h3 class="text-center text-lg font-poppinsBold">
          <span class="text-mulah-red font-bold font-inter">Simple</span
          ><span class="text-primary-black font-bold font-inter">Loyalty</span>
        </h3>
      </FlexRow>
      <FlexRow class="justify-center">
        <FlexCol class="grid h-screen place-items-center">
          <Spinner :width="16" :height="16" />
        </FlexCol>
      </FlexRow>
    </template>
    <template v-else>
      <LandingSearch />
    </template>
  </template>
  <template v-else>
    <MobileLayout :title="store.brandName">
      <FlexRow>
        <FlexCol class="container min-h-[40rem] overflow-y-auto">
          <RewardsPanel v-if="!loggedIn" :rewards="store.rewards" :user-points="userPoints" />
          <Links :links="store.links" />
          <GiveawayPreview />
        </FlexCol>
      </FlexRow>
      <FlexRow v-if="!loggedIn" class="pt-1"> </FlexRow>
      <MobileFooter v-if="loggedIn" :active="'Home'" />
    </MobileLayout>
  </template>
</template>

<script setup>
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import IconCone from '@/components/icons/IconCone.vue'
import RewardsPanel from '@/components/reusable/RewardsPanel.vue'
import Spinner from '@/components/reusable/Spinner.vue'
import LandingSearch from '@/pages/LandingSearch/Index.vue'
import Links from './Links.vue'
import GiveawayPreview from './GiveawayPreview.vue'
import { useRouter } from 'vue-router'
import { useBrandStore } from '../../stores/store'
import { usePhoneStore } from '../../stores/store'
import { ref, computed } from 'vue'

const router = useRouter()
const store = useBrandStore()
const phoneStore = usePhoneStore()
const userPoints = ref(0)

const links = computed(() => {
  return store.links
})

const loggedIn = computed(() => {
  return phoneStore.isLogin
})

const handleNextPage = () => {
  if (phoneStore.isLogin) {
    router.push({
      name: 'QualifiedRedemption'
    })
  } else {
    router.push({
      name: 'CheckPointsRewards'
    })
  }
}
</script>
