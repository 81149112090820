<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clip-path="url(#clip0_1_492)">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.925 2.65C11.2712 2.39036 11.6923 2.25 12.125 2.25C12.5577 2.25 12.9788 2.39036 13.325 2.65L20.325 7.9C20.5734 8.08629 20.775 8.32786 20.9139 8.60557C21.0527 8.88328 21.125 9.18951 21.125 9.5V19C21.125 19.5304 20.9143 20.0391 20.5392 20.4142C20.1641 20.7893 19.6554 21 19.125 21H14.225C13.9333 21 13.6535 20.8841 13.4472 20.6778C13.2409 20.4715 13.125 20.1917 13.125 19.9V14C13.125 13.7348 13.0196 13.4804 12.8321 13.2929C12.6446 13.1054 12.3902 13 12.125 13C11.8598 13 11.6054 13.1054 11.4179 13.2929C11.2304 13.4804 11.125 13.7348 11.125 14V19.9C11.125 20.0445 11.0965 20.1875 11.0413 20.321C10.986 20.4544 10.905 20.5757 10.8028 20.6778C10.7007 20.78 10.5794 20.861 10.446 20.9163C10.3125 20.9715 10.1695 21 10.025 21H5.125C4.59457 21 4.08586 20.7893 3.71079 20.4142C3.33571 20.0391 3.125 19.5304 3.125 19V9.5C3.125 9.18951 3.19729 8.88328 3.33615 8.60557C3.475 8.32786 3.67661 8.08629 3.925 7.9L10.925 2.65ZM12.125 4.25L5.125 9.5V19H9.125V14C9.125 13.2044 9.44107 12.4413 10.0037 11.8787C10.5663 11.3161 11.3294 11 12.125 11C12.9206 11 13.6837 11.3161 14.2463 11.8787C14.8089 12.4413 15.125 13.2044 15.125 14V19H19.125V9.5L12.125 4.25Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_492">
        <rect width="24" height="24" fill="white" transform="translate(0.125)" />
      </clipPath>
    </defs>
  </svg>
</template>
