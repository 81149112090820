<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5077 13.9904L18.201 22.2721L15.4794 18.7954C14.9127 18.0687 13.8627 17.9421 13.1394 18.5104C12.4144 19.0771 12.286 20.1254 12.8544 20.8487L16.906 26.0271C17.2227 26.4304 17.706 26.6654 18.2194 26.6654H18.231C18.746 26.6637 19.2327 26.4204 19.5444 26.0087L27.1594 16.0087C27.7177 15.2754 27.576 14.2321 26.8427 13.6737C26.1077 13.1154 25.0644 13.2587 24.5077 13.9904Z"
      fill="#1CB958"
    />
    <mask
      id="mask0_1301_5581"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="12"
      y="13"
      width="16"
      height="14"
    >
      <path
        d="M24.5077 13.9904L18.201 22.2721L15.4794 18.7954C14.9127 18.0687 13.8627 17.9421 13.1394 18.5104C12.4144 19.0771 12.286 20.1254 12.8544 20.8487L16.906 26.0271C17.2227 26.4304 17.706 26.6654 18.2194 26.6654H18.231C18.746 26.6637 19.2327 26.4204 19.5444 26.0087L27.1594 16.0087C27.7177 15.2754 27.576 14.2321 26.8427 13.6737C26.1077 13.1154 25.0644 13.2587 24.5077 13.9904Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1301_5581)"></g>
  </svg>
</template>
