export const GET_BRAND_SLUG = `
query BrandSlug(
  $sort: String
  $direction: String
  $filters: PromotionSearch
  $slug: String!
) {
  slBrandWithSlug(slug: $slug) {
    id
    name
    active
    websiteUrl
    deliveryUrl
    promotionUrl
    countryCode
    termsAndConditions
    promotions(filters: $filters, direction: $direction, sort: $sort) {
      id
      title
      cost
    }
    termsAndConditions
    countryCode
    giveaways {
      id
      rewardImageUrl
      termsAndConditions
      campaign {
        id
        title
      }
    }
  }
}
`

export const CHECKPOINTS_VALIDATION = `
  mutation CheckpointsValidation(
    $phoneNumber: String!
    $specification: String!
    $slug: String
    $websiteToken: String
    $countryCode: String!
  ) {
    slCheckpointsValidation(
      slug: $slug
      phoneNumber: $phoneNumber
      websiteToken: $websiteToken
      specification: $specification
      countryCode: $countryCode
    ) {
      registered
      allowSkipEmail
      metaVerified
      errors {
        message
      }
    }
  }
`

export const REGISTER_CUSTOMER = `
mutation RegisterCustomerInfo(
  $name: String!
  $email: String
  $birthdate: Datetime!
  $blacklist: Boolean!
  $brandSlug: String!
) {
  slRegisterCustomerInfo(
    name: $name
    email: $email
    birthdate: $birthdate
    blacklist: $blacklist
    brandSlug: $brandSlug
  ) {
    result
    errors {
      message
    }
  }
}
`

export const GET_CUSTOMER_POINTS = `
query Customer(
  $phoneNumber: String!
  $slug: String
) {
  slAppCustomer {
    id
    name
    membership(
      slug: $slug
    ) {
      title
    }
    brands: brandsWithSlug(slug: $slug) {
      id
      totalCollectedPoints(phoneNumber: $phoneNumber)
      totalAvailablePoints(phoneNumber: $phoneNumber)
    }
  }
}
`

export const GET_CUSTOMER_INFO = `
query Customer(
  $slug: String
) {
  slAppCustomer {
    id
    name
    email
    birthdate
    blacklist(slug: $slug)
  }
}
`

export const GET_MESSAGE_LOGS = `
query customerSmsLogs($brandId: ID!, $hidden: Boolean!) {
  customerSmsLogs(
    brandId: $brandId
    hidden: $hidden
  ) {
    id
    brandName
    message
    datetime
    channel
  }
}
`

export const GET_HISTORY = `
  query Customer($brandId: ID!) {
    customerHistory: slAppCustomer {
      id
      brandCollectedPoints(brandId: $brandId)
      deductedPoints(brandId: $brandId)
      redemptionCosts(brandId: $brandId)
      collectionsAndDeductions(brandId: $brandId) {
        amount
        title
        createdAt: createdIso
        expiryDate(brandId: $brandId)
        expiryDays(brandId: $brandId)
        specification
        void
      }
    }
  }
`

export const UPDATE_CUSTOMER_NAME_AND_EMAIL = `
  mutation UpdateCustomerNameAndEmail(
    $name: String!
    $email: String!
  ) {
    slUpdateCustomerNameAndEmail(
      name: $name
      email: $email
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const VERIFY_AUTH_CODE = `
  mutation VerifyAuthCode(
    $phoneNumber: String!
    $authCode: String!
  ) {
    slVerifyAuthCode(
      phoneNumber: $phoneNumber
      authCode: $authCode
    ) {
      result
      errors {
        message
      }
      cookie {
        name
        value
      }
    }
  }
`

export const CHECK_LOGIN = `
  query CheckLogin {
    slAppCustomer {
      phoneNumber
      withInfo
    }
  }
`

export const UPDATE_CUSTOMER_BLACKLIST = `
  mutation UpdateCustomerBlacklist(
    $brandId: ID!
    $blacklisted: Boolean!
  ) {
    slUpdateCustomerBlacklist(
      brandId: $brandId
      blacklisted: $blacklisted
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const CREATE_WEBSITE_VISIT = `
  mutation CreatWebsiteVisit(
    $id: ID!
  ) {
    slCreateWebsiteVisit(id: $id) {
      result
      errors {
        message
      }
    }
  }
`

export const CREATE_URL_CLICK = `
  mutation CreateUrlClick(
    $id: ID!
    $specification: String!
  ) {
    slCreateUrlClick(id: $id, specification: $specification) {
      result
      errors {
        message
      }
    }
  }
`

export const CREATE_SLUG_PROSPECT = `
  mutation CreateSlugProspect(
    $slug: String!
  ) {
    slCreateSlugProspect(slug: $slug) {
      result
      errors {
        message
      }
    }
  }
`

export const UPDATE_CUSTOMER_NAME_EMAIL_META = `
  mutation UpdateCustomerNameAndEmail(
    $name: String!
    $email: String!
    $metaVerified: Boolean
  ) {
    slUpdateCustomerNameAndEmail(
      name: $name
      email: $email
      metaVerified: $metaVerified
    ) {
      result
      errors {
        message
      }
    }
  }
`

//GOOGLE REVIEW
export const GENERATE_REVIEW_SESSION = `
  mutation GenerateReviewSession(
    $slug: String!
    $outletId: ID!
    $campaignId: ID!
  ) {
    slGenerateReviewSession(
      slug: $slug
      outletId: $outletId
      campaignId: $campaignId
    ) {
      result
      errors {
        message
      }
      uniqueHashtag
      defaultHashtags
      customHashtags
    }
  }
`

export const SUBSCRIBE_SL_APP_CHANNEL = `
  subscription {
    customerInstaToken {
      token
      message
    }
  }
`

export const GET_INSTA_USER_INFO = `
query GetInstaUserInfo(
    $accessToken: String!
) {
    slAppCustomer {
        instaUserInfo(accessToken: $accessToken) {
            id
            username
        }
    }
}
`

export const VERIFY_REVIEW = `
  mutation VerifyReview(
    $slug: String!
    $uniqueHashtag: String!  
  ) {
    slVerifyReview(
      slug: $slug
      uniqueHashtag: $uniqueHashtag
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const VALIDATE_REVIEW_SPEND = `
  mutation ValidateReviewSpend (
    $slug: String!
    $phoneNumber: String!
    $countryCode: String!
    $uniqueHashtag: String!
    $outletId: ID!
    $campaignId: ID!
  ) {
    slValidateReviewSpend (
      slug: $slug
      phoneNumber: $phoneNumber
      countryCode: $countryCode
      uniqueHashtag: $uniqueHashtag
      outletId: $outletId
      campaignId: $campaignId
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const GIVEAWAY_CRITERIA = `
  query GiveawayCriteria(
    $giveawayOrganizerId: ID!
    $instaUsername: String
  ) {
    slAppCustomer {
      instaGiveawayCriteria(
        giveawayOrganizerId: $giveawayOrganizerId
        instaUsername: $instaUsername
      ) {
        status
        message
        taggedFriend
        taggedBusiness
        reachedSpending
      }
    }
  }
`

export const UPDATE_TOTAL_SCANS = `
  mutation UpdateTotalScans(
    $campaignId: ID!
  ) {
    slUpdateTotalScans(
      campaignId: $campaignId
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const GET_REVIEW_CAMPAIGN = `
query ReviewCampaign(
  $slug: String!,
  $reviewId: String!
) {
  slReviewCampaign(slug: $slug, reviewId: $reviewId) {
    campaignData {
      title
      promotionName
      startTiming
      endTiming
      minSpend
      imageUrl
      campaignId
      countryId
      enabledOutlets {
        id
        name
        placeId
      }
    }
    redeemedOutlets
  }
}
`

export const EXPIRE_REVIEW_SESSION = `
  mutation ExpireReviewSession(
    $slug: String!
    $uniqueHashtag: String!
  ) {
    slExpireReviewSession(
      slug: $slug
      uniqueHashtag: $uniqueHashtag
    ) {
      result
      errors {
        message
      }
    }
  }
`

export const GIVEAWAY = `
  query Giveaway($organizerId: ID!) {
    giveaway(organizerId: $organizerId) {
      id
      instagramUrl
      spendingThreshold
      friendTagThreshold
      shareTagPost
      termsAndConditions
      rewardImageUrl
      campaign {
        id
        title
      }
    }
  }
`

export const BRAND_SEARCH = `
query brandSearch($searchStr: String!) {
  brandSearch(searchStr: $searchStr) {
    id
    name
    logoUrl
    active
    slug
    countryCode
  }
}
`

export const CUSTOMER_BRANDS = `
query customerBrands {
  customerBrands {
    id
    name
    logoUrl
    active
    slug
    countryCode
  }
}
`
export const CLAIM_GIVEAWAY = `
  mutation ClaimGiveaway(
  $giveawayId: ID!
  $phoneNumber: String!
  $countryCode: String!
) {
    claimGiveaway(
    giveawayId: $giveawayId
    phoneNumber: $phoneNumber
    countryCode: $countryCode
) {
      result
      errors {
        message
      }
    }
  }
`

export const CHANCE_SESSION = `
query ChanceSession(
  $id: String!
) {
  slChanceSession(
    id: $id
  ) {
      chanceOrganizer {
        id 
        startDate
        endDate
        minSpendRequired
        minSpend
        expiry
        termsAndConditions
        chancePrizes {
          id
          promotion {
              id
              title
          }
        }
      }
  }
}
`

export const RUN_CHANCE_CAMPAIGN = `
mutation RunChanceCampaign (
  $sessionId: String!
) {
  slRunChanceCampaign (
    sessionId: $sessionId
  ) {
    result 
    prize {
      id
      promotion {
        id
        title
      }
    }
    errors {
      message
    }
  }
}
`

export const REDEEM_CHANCE_REWARD = `
mutation RedeemChanceReward (
  $sessionId: String!
) {
  slRedeemChanceReward (
    sessionId: $sessionId
  ) {
    result 
    errors {
      message
    }
  }
}
`

export const GIVEAWAY_VISIT_TRACKING = `
mutation GiveawayVisitTracking (
  $organizerId: ID!
  $metadata: String!
){
 giveawayVisitTracking(
  organizerId: $organizerId
  metadata: $metadata
){
  result
  trackingId
  errors {
    message
  }
}
}
`

export const UPDATE_GIVEAWAY_VISIT_TRACKING = `
 mutation UpdateGiveawayVisitTracking (
  $trackingId: ID!
  $metadata: String!
) { 
  updateGiveawayVisitTracking(
    trackingId: $trackingId
    metadata: $metadata
  ) {
  result
  errors {
    message
   }
  }
 }
`

export const VERIFY_INSTA_GIVEAWAY_CRITERIA = `
 mutation VerifyInstaGiveawayCriteria (
  $giveawayOrganizerId: ID!
  $instaUsername: String
  $phoneNumber: String!
  $countryCode: String!
 ) {
   verifyInstaGiveawayCriteria (
    giveawayOrganizerId: $giveawayOrganizerId
    instaUsername: $instaUsername
    phoneNumber: $phoneNumber
    countryCode: $countryCode
   ) {
    result
    errors {
      message
   }
  }
}
`

export const TRIGGER_REVIEW_VERIFICATION = `
 mutation TriggerReviewVerification (
  $slug: String!
  $outletId: ID!
  $campaignId: ID!
  $uniqueHashtag: String!
 ) {
  slTriggerReviewVerification (
    slug: $slug
    outletId: $outletId
    campaignId: $campaignId
    uniqueHashtag: $uniqueHashtag
  ) {
    result 
    errors {
      message
    }
  }
 }
`
