<template>
  <div class="h-[45vh] overflow-auto mt-2">
    <FlexCol v-for="giveaway in giveaways" :key="giveaway.id" class="pb-2">
      <div class="border-2 rounded cursor-pointer" @click="moveToGiveaway(giveaway.id)">
        <div class="flex flex-row">
          <img
            :src="
              giveaway.rewardImageUrl
                ? giveaway.rewardImageUrl
                : require('@/assets/images/quest/giveaway_default_pic.png')
            "
            alt="image"
            class="object-cover h-[10rem] w-[10rem] flex border-solid border-r-2"
          />
          <FlexCol class="justify-between my-4 mx-4">
            <label class="flex font-bold text-2xl text-wrap">{{ giveaway.campaign.title }}</label>
            <!-- <div class="flex text-xs font-medium">
              1000 people already participates in this giveaways!
            </div> -->
          </FlexCol>
        </div>
      </div>
    </FlexCol>
  </div>
</template>

<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import { useRouter } from 'vue-router'
import { useBrandStore } from '../../stores/store'
import { usePhoneStore } from '../../stores/store'
import { onMounted, ref } from 'vue'

const giveaways = ref([])

const store = useBrandStore()
const phoneStore = usePhoneStore()
const router = useRouter()

onMounted(() => {
  giveaways.value = store.giveaways
})

const moveToGiveaway = (orgId) => {
  router.push({ name: 'Giveaway', params: { slug: store.slugUrl, id: orgId } })
}
</script>
