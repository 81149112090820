<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_0_399)">
      <g>
        <path
          fill="currentColor"
          d="M14.375 3C16.6293 3 18.7913 3.89553 20.3854 5.48959C21.9795 7.08365 22.875 9.24566 22.875 11.5C22.875 13.7543 21.9795 15.9163 20.3854 17.5104C18.7913 19.1045 16.6293 20 14.375 20H13.875V20.99C13.875 21.1227 13.8488 21.2541 13.798 21.3767C13.7472 21.4993 13.6727 21.6107 13.5788 21.7045C13.4849 21.7983 13.3735 21.8727 13.2508 21.9234C13.1282 21.9741 12.9967 22.0001 12.864 22C10.404 21.998 7.912 21.177 6.021 19.496C4.113 17.798 2.877 15.275 2.875 12.009V11.5C2.875 9.24566 3.77053 7.08365 5.36459 5.48959C6.95865 3.89553 9.12066 3 11.375 3H14.375ZM14.375 5H11.375C9.65109 5 7.99779 5.68482 6.77881 6.90381C5.55982 8.12279 4.875 9.77609 4.875 11.5L4.876 12.165C4.916 14.807 5.917 16.727 7.351 18.001C8.589 19.103 10.192 19.76 11.875 19.945V19.01C11.875 18.452 12.327 18 12.885 18H14.375C16.0989 18 17.7522 17.3152 18.9712 16.0962C20.1902 14.8772 20.875 13.2239 20.875 11.5C20.875 9.77609 20.1902 8.12279 18.9712 6.90381C17.7522 5.68482 16.0989 5 14.375 5ZM9.375 10C9.77282 10 10.1544 10.158 10.4357 10.4393C10.717 10.7206 10.875 11.1022 10.875 11.5C10.875 11.8978 10.717 12.2794 10.4357 12.5607C10.1544 12.842 9.77282 13 9.375 13C8.97718 13 8.59564 12.842 8.31434 12.5607C8.03304 12.2794 7.875 11.8978 7.875 11.5C7.875 11.1022 8.03304 10.7206 8.31434 10.4393C8.59564 10.158 8.97718 10 9.375 10ZM16.375 10C16.7728 10 17.1544 10.158 17.4357 10.4393C17.717 10.7206 17.875 11.1022 17.875 11.5C17.875 11.8978 17.717 12.2794 17.4357 12.5607C17.1544 12.842 16.7728 13 16.375 13C15.9772 13 15.5956 12.842 15.3143 12.5607C15.033 12.2794 14.875 11.8978 14.875 11.5C14.875 11.1022 15.033 10.7206 15.3143 10.4393C15.5956 10.158 15.9772 10 16.375 10Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_399">
        <rect width="24" height="24" fill="white" transform="translate(0.875)" />
      </clipPath>
    </defs>
  </svg>
</template>
