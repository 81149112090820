<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <MobileLayout :title="store.brandName">
    <FlexCol class="container min-h-[72.5vh] overflow-y-auto">
      <FlexCol class="justify-between" style="min-height: inherit">
        <FlexRow>
          <FlexCol class="w-full">
            <h1 class="font-poppinsBold text-3xl text-primary-black my-2">
              Please fill up your details
            </h1>
            <FlexCol class="my-2">
              <label class="block mb-2 text-sm font-medium text-gray-900">Name</label>
              <FormInput placeholder="Your name" v-model="name" />
            </FlexCol>
            <FlexCol class="my-2">
              <label class="block mb-2 text-sm font-medium text-gray-900">Birthday</label>
              <VueDatePicker
                v-model="date"
                placeholder="dd/mm/yyyy"
                model-type="yyyy-MM-dd"
                format="dd/MM/yyyy"
                auto-apply
                hide-input-icon
                input-class-name="custom-date-picker"
                :hide-offset-dates="true"
                :enable-time-picker="false"
                :max-date="new Date()"
              />
            </FlexCol>
            <FlexCol class="my-2">
              <label class="block mb-2 text-sm font-medium text-gray-900">Email</label>
              <FormInput placeholder="Your email" v-model="email" />
            </FlexCol>
            <FlexRow class="items-start my-3 gap-x-4">
              <span>
                <input
                  v-model="terms"
                  type="checkbox"
                  class="w-5 h-5 text-black bg-gray-100 border-gray-300 rounded-full focus:ring-0 ring-0 focus:ring-offset-0"
                />
              </span>
              <p class="text-sm">
                I accept the term of service, privacy policy and data processing agreement.
              </p>
            </FlexRow>
            <FlexCol>
              <AccordianCollapse accordian-id="option-accordian">
                <AccordianHeading
                  heading-id="option-accordian-heading"
                  collapse-id="option-accordian-body"
                  utils="border-0 px-1 py-2.5 justify-start"
                >
                  <span class="text-primary-black text-sm mx-4">Option</span>
                </AccordianHeading>
                <AccordianBody
                  heading-id="option-accordian-heading"
                  collapse-id="option-accordian-body"
                  utils="border-0 px-0 pt-2 pb-4"
                >
                  <FlexRow class="items-start gap-x-4">
                    <span>
                      <input
                        v-model="blacklist"
                        type="checkbox"
                        class="w-5 h-5 text-black bg-gray-100 border-gray-300 rounded-full focus:ring-0 ring-0 focus:ring-offset-0"
                      />
                    </span>
                    <p class="text-sm">
                      Get useful tips, inspiration, and offers via e-communication.
                    </p>
                  </FlexRow>
                </AccordianBody>
              </AccordianCollapse>
            </FlexCol>
          </FlexCol>
        </FlexRow>
        <FlexRow class="mb-20 mt-6">
          <FlexCol class="w-full">
            <Button variant="mulah" size="lg" :disabled="!valid" @click="handleLogin()"
              >Log in</Button
            >
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </FlexCol>
    <template #footer>
      <MobileFooter />
    </template>
  </MobileLayout>
</template>

<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import AccordianCollapse from '@/components/uielements/Accordian/AccordianCollapse.vue'
import AccordianHeading from '@/components/uielements/Accordian/AccordianHeading.vue'
import AccordianBody from '@/components/uielements/Accordian/AccordianBody.vue'
import FormInput from '@/components/uielements/FormInput/FormInput.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useMutation } from '@urql/vue'
import { useBrandStore } from '../../stores/store'
import { usePhoneStore } from '../../stores/store'
import { REGISTER_CUSTOMER } from '../../constants/graphql'
import { computed, ref } from 'vue'
import router from '../../router'

const name = ref('')
const email = ref('')
const date = ref('')
const terms = ref(true)
const blacklist = ref(true)
const store = useBrandStore()
const phoneStore = usePhoneStore()
const { executeMutation: registerCustomer } = useMutation(REGISTER_CUSTOMER)

const valid = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  if (
    name.value !== '' &&
    email.value !== '' &&
    emailRegex.test(email.value) &&
    date.value !== '' &&
    terms.value !== false
  ) {
    return true
  } else {
    return false
  }
})

const handleLogin = async () => {
  if (valid.value === true) {
    await registerCustomer({
      name: name.value,
      email: email.value,
      birthdate: date.value,
      blacklist: !blacklist.value,
      brandSlug: store.slugUrl
    })
    phoneStore.$patch({
      registered: true
    })
    if (phoneStore.metaVerified === true) {
      router.push({
        name: 'QualifiedRedemption'
      })
    } else if (phoneStore.metaVerified === false) {
      router.push({
        name: 'Meta'
      })
    }
  }
}
</script>

<style scoped></style>
