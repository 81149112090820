<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="sticky bg-white margin-0">
    <FlexRow class="justify-center w-full items-center relative py-1">
      <FlexCol class="align-middle">
        <h3 class="text-center text-lg font-poppinsBold text-primary-black">{{ props.title }}</h3>
        <p class="text-secondary-black text-xs text-center">
          Powered by
          <span class="text-mulah-red font-inter">Simple</span>
          <span class="text-primary-black font-bold font-inter">Loyalty</span>
        </p>
      </FlexCol>
      <div class="absolute right-0">
        <button type="button" class="text-icon-grey focus:outline-none" @click="showSideNav = true">
          <BurgerIcon class="w-6 h-6" />
        </button>
      </div>
    </FlexRow>
  </section>
  <div class="min-h-fit min-w-full">
    <slot></slot>
  </div>
  <SideNav :showSideNav="showSideNav" @closeSideNav="showSideNav = false" />
  <slot name="footer"></slot>
</template>
<script setup>
import SideNav from './SideNav/SideNav.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import BurgerIcon from '@/components/icons/IconBurger.vue'
import { ref } from 'vue'

const showSideNav = ref(false)

const props = defineProps({
  title: String
})
</script>
