<template>
  <FlexRow class="justify-center">
    <FlexCol class="items-center container min-h-[42rem]">
      <div class="text-mulah-red mt-16 mb-4">
        <IconStopWatch class="w-20 h-20" v-if="props.notificationType === 'EXPIRED'" />
        <IconExclamation class="w-20 h-20" v-else-if="props.notificationType === 'NO_REVIEW'" />
        <IconCheckFill class="w-20 h-20" v-else-if="props.notificationType === 'OK'" />
        <IconFrown class="w-20 h-20" v-else />
      </div>
      <h2 class="font-poppinsBold text-[#2f2f2f] text-3xl mb-1 text-center">
        {{ notificationContent.title }}
      </h2>
      <h6 class="text-icon-grey text-lg text-center">
        {{ notificationContent.message }}

        <br />
        <br />
        <span v-if="props.notificationType === 'INSUFFICIENT_CREDITS'">
          No worries though, we will send it to you as soon as possible!</span
        >
      </h6>

      <FlexCol
        class="border-2 w-11/12 p-4 mt-4 rounded-full"
        v-if="props.notificationType === 'OK'"
      >
        <span class="text-blue-500 text-center"
          >{{ props.uniqueHashtag }} {{ props.selectedHashtags }}</span
        >
      </FlexCol>

      <FlexCol
        class="border-2 border-black w-full p-4 mt-6 rounded-xl"
        v-if="props.notificationType === 'OK'"
      >
        <span style="color: #ff0000" class="font-semibold">Note:</span>
        <span
          >Google may take up to 7 days to publish your review, but we'll be keeping an eye
          out.</span
        >
        <span class="mt-3">
          Once it's published, we'll send you your reward SMS. Get ready for an exciting treat to make
          your next visit even better!
        </span>
      </FlexCol>
    </FlexCol>
  </FlexRow>
  <FlexRow>
    <FlexCol class="mt-5 w-full justify-center">
      <div v-if="props.notificationType === 'NO_MIN_SPEND'">
        <Timer
          class="text-center"
          :cookie-name="'greview-cookie'"
          :session-duration="900"
          @timer-ended="handleTimerEnded"
        />
        <Button class="w-full mb-2 mt-1" variant="mulah" size="lg" @click="retryRedeem">
          I've completed it now
        </Button>
      </div>

      <Button
        v-if="props.notificationType !== 'INVALID_SLUG' && props.notificationType !== 'NO_CAMPAIGN'"
        class="hover:bg-white hover:text-red-500"
        :variant="notificationContent.buttonColor"
        size="lg"
        @click="navigateTo"
        >{{ notificationContent.buttonText }}</Button
      >
    </FlexCol>
  </FlexRow>
</template>

<script setup>
import { defineProps, computed } from 'vue'

import IconStopWatch from '@/components/icons/IconStopWatch.vue'
import IconFrown from '@/components/icons/IconFrown.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import Button from '@/components/uielements/Button/Button.vue'
import IconExclamation from '@/components/icons/IconExclamation.vue'
import IconCheckFill from '@/components/icons/IconCheckFill.vue'
import Timer from '@/pages/GoogleReview/Components/Timer.vue'

const props = defineProps({
  notificationType: {
    type: String,
    required: true
  },
  minSpend: {
    type: Number,
    required: true
  },
  promotionName: {
    type: String,
    required: true
  },
  brandName: {
    type: String,
    required: true
  },
  campaignName: {
    type: String,
    required: true
  },
  enabledOutletCount: {
    type: Number,
    required: true
  },
  currencyCode: {
    type: String,
    required: true
  },
  selectedOutlet: {
    type: String,
    required: true
  },
  selectedHashtags: {
    type: String,
    required: true
  },
  uniqueHashtag: {
    type: String,
    required: true
  }
})

const notificationContent = computed(() => {
  switch (props.notificationType) {
    case 'EXPIRED':
      return {
        title: 'Session Expired',
        message:
          'The "' + props.campaignName + '" campaign session time has expired. Please try again!',
        buttonText: 'Try Again',
        buttonColor: 'mulah'
      }
    case 'CAMPAIGN_EXPIRED':
      return {
        title: 'Campaign Expired',
        message: 'The "' + props.campaignName + '" campaign has expired.',
        buttonText: 'Back to Home',
        buttonColor: 'mulah'
      }
    case 'PRIZE_CLAIMED':
      if (props.enabledOutletCount > 1) {
        return {
          title: 'Prize is already claimed',
          message:
            'You have already claimed the prize for this store. Please visit other participating store(s) to leave a review and claim a prize.',
          buttonText: 'Leave another review',
          buttonColor: 'mulah'
        }
      } else {
        return {
          title: 'Prize is already claimed',
          message: 'You have already claimed the prize for this store.',
          buttonText: 'Back to Home',
          buttonColor: 'mulah'
        }
      }
    case 'OK':
      return {
        title: "Your Reward is on it's way!",
        message: 'Thank you for submitting a Google Review for ' + props.selectedOutlet + '!',
        buttonText: 'Back to Home',
        buttonColor: 'mulah'
      }
    case 'NO_MIN_SPEND':
      return {
        title: 'Whoops!',
        message:
          'Please spend at least ' +
          props.currencyCode +
          props.minSpend +
          ' at the store to claim your reward',
        buttonText: 'Leave another review',
        buttonColor: 'outline-mulah'
      }
    case 'NO_REVIEW':
      return {
        title: 'Please Try Again!',
        message: 'We could not find your review. Please delete your post and try again.',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    case 'ALREADY_REDEEMED':
      return {
        title: 'Already Redeemed!',
        message: 'Redemption has already been done for that unique hashtag',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    case 'INVALID_SESSION':
      return {
        title: 'We are Sorry!',
        message: 'The unique hashtag is invalid',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    case 'INVALID_SLUG':
      return {
        title: 'We are Sorry!',
        message: 'This brand does not exist',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    case 'NO_CAMPAIGN':
      return {
        title: 'We are Sorry!',
        message: 'This campaign does not exist',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    case 'INSUFFICIENT_CREDITS':
      return {
        title: 'Whoops!',
        message:
          "You are eligible for your reward but we can't send it right now as we're out of credits.",
        buttonText: 'Back to Home',
        buttonColor: 'mulah'
      }
    case 'NOT_VERIFIED':
      return {
        title: 'We are Sorry!',
        message: 'Your Google Review has not been verified',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
    default:
      return {
        title: 'We are Sorry!',
        message: 'Something went wrong. Please try again.',
        buttonText: 'Done',
        buttonColor: 'mulah'
      }
  }
})

const emit = defineEmits(['go-to-step', 'timer-ended', 'retry-redeem'])

function navigateTo() {
  if (props.notificationType === 'NO_REVIEW') {
    emit('go-to-step', '2')
  } else {
    if (notificationContent.value.buttonText === 'Back to Home') {
      emit('go-to-step', 'reward-page')
    } else {
      emit('go-to-step', '1')
    }
  }
}

function handleTimerEnded() {
  emit('timer-ended')
}

function retryRedeem() {
  emit('retry-redeem')
}
</script>
