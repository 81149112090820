<template>
  <teleport to="body">
    <ModalBackdrop>
      <ModalContainer>
        <ModalBody>
          <FlexCol class="gap-y-3 items-center">
            <div class="text-mulah-red">
              <IconCircleSuccess width="80" height="80" />
            </div>
            <h4 class="font-poppinsBold text-lg text-center">Giveway Redeemed!</h4>
            <span>Please check your SMS and show the code to the cashier to claim!</span>
            <Button variant="mulah" size="sm" class="w-full" @click="handleComplete">Done</Button>
          </FlexCol>
        </ModalBody>
      </ModalContainer>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalContainer from '@/components/uielements/Modal/ModalContainer.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconCircleSuccess from '@/components/icons/IconCircleSuccess.vue'
import { useRouter } from 'vue-router'

const emit = defineEmits(['close-modal'])
const router = useRouter()

const handleComplete = () => {
  emit('close-modal')
  router.push({ path: '/', replace: true })
}
</script>
