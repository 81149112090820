import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFbUserDetails = defineStore('fbStore', () => {
  const id = ref(null);
  const name = ref();
  const email = ref();

  return { id, name, email };
})
