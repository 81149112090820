<template>
  <FlexRow class="justify-between items-center gap-x-4">
    <Button
      variant="outline"
      size="lg"
      class="basis-1/4"
      :disabled="props.btnPrevDisabled"
      :class="{ 'btn-disabled': btnPrevDisabled }"
      @click="handlePrevClicked()"
    >
      <template #icon>
        <IconArrowLeft class="my-[0.4rem]" />
      </template>
    </Button>
    <Button
      variant="mulah"
      size="lg"
      class="basis-3/4"
      :disabled="props.btnNextDisabled"
      :class="{ 'btn-disabled': btnNextDisabled }"
      @click="handleNextClicked()"
    >
      {{ props.btnNextTitle }}
    </Button>
  </FlexRow>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import Button from '@/components/uielements/Button/Button.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'

import { useRouter } from 'vue-router'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  btnNextDisabled: {
    type: Boolean,
    default: false
  },
  btnNextTitle: {
    type: String,
    required: true
  },
  btnPrevDisabled: {
    type: Boolean,
    default: false
  },
  switchToNext: {
    // routes name
    type: String,
    default: ''
  },
  switchToPrev: {
    // routes name
    type: String,
    default: ''
  }
})

// eslint-disable-next-line no-unused-vars
const emits = defineEmits(['btn-next-func', 'btn-prev-func'])

const router = useRouter()

function handleNextClicked() {
  emits('btn-next-func')
  if (props.switchToNext) {
    handleSwitchPage(props.switchToNext)
  }
}

function handlePrevClicked() {
  emits('btn-prev-func')
  if (props.switchToPrev) {
    handleSwitchPage(props.switchToPrev)
  }
}

const handleSwitchPage = (page) => {
  router.push({ name: page })
}
</script>

<style scoped>
.btn-disabled {
  background-color: gray !important;
  color: white !important;
  cursor: not-allowed !important;
  border-color: gray !important; /* For outline button */
}
</style>
