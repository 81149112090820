<template>
  <FlexCol class="mt-2">
    <FlexRow
      ><img
        :src="
          props.giveawayImg
            ? props.giveawayImg
            : require('@/assets/images/quest/giveaway_default_pic.png')
        "
        class="h-auto max-w-full max-h-72 m-auto"
      />
    </FlexRow>
  </FlexCol>
  <FlexCol>
    <label class="font-bold text-xl pt-10 pb-1">Enter your phone number</label>
    <label class="text-[#717171] text-sm pb-1 mb-5"
      >Enter your number to see if you are eligible.
    </label>
  </FlexCol>
  <FlexCol class="mb-4">
    <FlexRow>
      <input
        class="border border-gray-300 text-gray-900 text-md rounded-lg focus:border-blue-600 w-full p-3 focus:ring-0 ring-offset-0 my-5"
        id="intlPhone"
        type="tel"
        v-model="inputVal"
        :style="{
          border: validNumber ? '2px solid black' : '2px solid #e0e0e0'
        }"
      />
    </FlexRow>
  </FlexCol>

  <FlexRow class="mb-20 mt-4 justify-between">
    <Button class="w-2/12 me-2" variant="outline-secondary" @click="emit('go-back')"
      ><BackIcon
    /></Button>
    <Button class="w-10/12" variant="mulah" :disabled="!validNumber" @click="getReward"
      >Get Reward
    </Button>
  </FlexRow>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import { useBrandStore } from '../../stores/store'
import { useRoute, useRouter } from 'vue-router'
import { useMutation } from '@urql/vue'
import { VERIFY_INSTA_GIVEAWAY_CRITERIA, CLAIM_GIVEAWAY } from '@/constants/graphql.js'

import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import BackIcon from '@/components/icons/IconArrowLeft.vue'

const dialCode = ref(null)
const inputVal = ref('')
const validNumber = ref(false)
const intlInputLoading = ref(true)
const store = useBrandStore()
const selectedCountry = ref(store.countryCode)
const loadingMutation = ref(false)
const loadingGiveawayMutation = ref(false)

const router = useRouter()

const { executeMutation: verifyPhoneNumber } = useMutation(VERIFY_INSTA_GIVEAWAY_CRITERIA)
const { executeMutation: claimGiveaway } = useMutation(CLAIM_GIVEAWAY)

const getReward = async () => {
  if (loadingMutation.value) return
  loadingMutation.value = true

  const args = {
    giveawayOrganizerId: props.giveawayOrganizerId,
    instaUsername: props.instaUsername,
    phoneNumber: `${dialCode.value}${inputVal.value}`,
    countryCode: selectedCountry.value
  }

  try {
    const response = await verifyPhoneNumber(args)

    const result = response.data.verifyInstaGiveawayCriteria.result

    const parsedResult = JSON.parse(result)

    const reachedSpending = parsedResult.reached_spending

    if (reachedSpending) {
      redeemGiveaway()
    } else {
      emit('not-enough-spent')
    }
  } catch (e) {
    console.error(e)
  } finally {
    loadingMutation.value = false
  }
}

const redeemGiveaway = async () => {
  if (loadingGiveawayMutation.value) return
  loadingGiveawayMutation.value = true

  const args = {
    giveawayId: props.giveawayOrganizerId,
    countryCode: selectedCountry.value,
    phoneNumber: `${dialCode.value}${inputVal.value}`
  }
  try {
    const response = await claimGiveaway(args)
    if (response.data.claimGiveaway.result === 'OK') {
      emit('success')
    } else if (response.data.claimGiveaway.errors[0].message === 'Voucher exists') {
      alert('You have already redeemed this reward!')
    } else {
      alert('Failed to redeem reward')
    }
  } catch (e) {
    alert('Failed to redeem reward')
    console.error(e)
  } finally {
    loadingGiveawayMutation.value = false
  }
}

onMounted(() => {
  initializePhoneNumberInput()
})

const initializePhoneNumberInput = async () => {
  const input = document.querySelector('#intlPhone')

  const country = selectedCountry.value

  const iti = intlTelInput(input, {
    initialCountry: country,
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
  })

  const getDialCode = (iti) => {
    dialCode.value = iti.getSelectedCountryData().dialCode
  }

  getDialCode(iti)

  input.addEventListener('countrychange', () => {
    getDialCode(iti)
    selectedCountry.value = iti.getSelectedCountryData().iso2.toUpperCase()
    inputVal.value = ''
  })
  intlInputLoading.value = false
}

watch(inputVal, (newVal) => {
  validNumber.value = isValidPhoneNumber(`+${dialCode.value}${newVal}`)
})

const props = defineProps({
  giveawayImg: {
    type: String
  },
  giveawayOrganizerId: {
    type: String
  },
  instaUsername: {
    type: String
  }
})

const emit = defineEmits(['go-back', 'not-enough-spent', 'success'])
</script>

<style scoped>
:deep(.iti) {
  width: 100%;
}
</style>
