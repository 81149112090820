import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePhoneStore = defineStore('checkPhoneInfo', {
  state: () => {
    return {
      selectedCountry: '',
      locationPath: '',
      trunkPrefix: '',
      phoneNumber: '',
      phoneNumberWithPrefix: '',
      fullPhoneNum: '',
      isLogin: '',
      metaVerified: false
    }
  }
})

export const useBrandStore = defineStore('brandStore', {
  state: () => {
    return {
      slugUrl: '',
      brandId: '',
      brandName: '',
      rewards: {},
      tnc: null,
      countryCode: 'MY',
      rewards: [],
      giveaways: [],
      links: [
        { title: 'Website Link', url: '', specification: 'website' },
        { title: 'Delivery Link', url: '', specification: 'delivery' },
        { title: 'Promotion Link', url: '', specification: 'promotion' }
      ],
      brandQueryFetching: false
    }
  }
})
