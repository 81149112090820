<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        stroke="currentColor"
        d="M9.52799 5.408C10.795 3.136 11.428 2 12.375 2C13.322 2 13.955 3.136 15.222 5.408L15.55 5.996C15.91 6.642 16.09 6.965 16.37 7.178C16.65 7.391 17 7.47 17.7 7.628L18.336 7.772C20.796 8.329 22.025 8.607 22.318 9.548C22.61 10.488 21.772 11.469 20.095 13.43L19.661 13.937C19.185 14.494 18.946 14.773 18.839 15.117C18.732 15.462 18.768 15.834 18.84 16.577L18.906 17.254C19.159 19.871 19.286 21.179 18.52 21.76C17.754 22.342 16.602 21.811 14.3 20.751L13.703 20.477C13.049 20.175 12.722 20.025 12.375 20.025C12.028 20.025 11.701 20.175 11.046 20.477L10.451 20.751C8.14799 21.811 6.99599 22.341 6.23099 21.761C5.46399 21.179 5.59099 19.871 5.84399 17.254L5.90999 16.578C5.98199 15.834 6.01799 15.462 5.90999 15.118C5.80399 14.773 5.56499 14.494 5.08899 13.938L4.65499 13.43C2.97799 11.47 2.13999 10.489 2.43199 9.548C2.72499 8.607 3.95499 8.328 6.41499 7.772L7.05099 7.628C7.74999 7.47 8.09899 7.391 8.37999 7.178C8.65999 6.965 8.83999 6.642 9.19999 5.996L9.52799 5.408Z"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
