<template>
  <FlexRow v-if="checkLoading" class="justify-center">
    <FlexCol class="grid h-screen place-items-center">
      <Spinner :width="16" :height="16" />
    </FlexCol>
  </FlexRow>
  <router-view v-else></router-view>
</template>

<script setup>
import Spinner from '@/components/reusable/Spinner.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'

import { watch, computed, onMounted, ref } from 'vue'
import { useMutation, useQuery } from '@urql/vue'
import { useBrandStore, usePhoneStore } from './stores/store'
import {
  CHECK_LOGIN,
  GET_BRAND_SLUG,
  CREATE_WEBSITE_VISIT,
  CREATE_SLUG_PROSPECT
} from '@/constants/graphql.js'
import { useRoute, useRouter } from 'vue-router'

const slug = ref(null)

const route = useRoute()
const router = useRouter()
const store = useBrandStore()
const phoneStore = usePhoneStore()
const checkLoading = ref(true)

onMounted(() => {
  const slug = $cookies.get('slSlug')
  if (slug) {
    store.$patch({ slugUrl: slug })
  }
})

const { executeMutation: createWebsiteVisit } = useMutation(CREATE_WEBSITE_VISIT)
const { executeMutation: createSlugProspect } = useMutation(CREATE_SLUG_PROSPECT)

const isLogin = useQuery({ query: CHECK_LOGIN })
const brandSlug = useQuery({
  query: GET_BRAND_SLUG,
  variables: {
    phoneNumber: '',
    sort: 'cost',
    direction: 'ASC',
    filters: {
      status: 'active',
      title: '',
      specification: 'Point Reward'
    },
    slug: slug
  },
  pause: computed(() => !slug.value)
})

watch(isLogin.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    let loginStatus = false
    let phoneNumber = ''

    if (isLogin.data.value.slAppCustomer !== null) {
      loginStatus = true
      phoneNumber = isLogin.data.value.slAppCustomer.phoneNumber
    }
    phoneStore.$patch({
      isLogin: loginStatus,
      phoneNumberWithPrefix: phoneNumber
    })

    checkLoading.value = false
  }
})

watch(
  () => route.params,
  (param) => {
    const slugCookie = $cookies.get('slSlug')
    if (param.slug && param.slug !== '') {
      slug.value = param.slug
      store.$patch({ slugUrl: route.params.slug })
      $cookies.set('slSlug', slug.value, '1y', null, process.env.VUE_APP_COOKIE_DOMAIN, true)
    } else if (slugCookie) {
      slug.value = slugCookie
      store.$patch({ slugUrl: slugCookie })
    }
  }
)

watch(brandSlug.fetching, async (fetchStatus) => {
  store.brandQueryFetching = fetchStatus

  if (!fetchStatus) {
    if (!brandSlug.data.value.slBrandWithSlug) {
      store.$reset()
      router.push({ path: '/', replace: true })
    } else {
      const {
        id,
        name,
        countryCode,
        websiteUrl,
        deliveryUrl,
        promotionUrl,
        termsAndConditions,
        promotions,
        giveaways
      } = brandSlug.data.value.slBrandWithSlug

      store.$patch({
        brandId: id,
        brandName: name,
        countryCode: countryCode,
        giveaways: giveaways,
        tnc: termsAndConditions,
        rewards: promotions
      })

      store.links[0].url = websiteUrl
      store.links[1].url = deliveryUrl
      store.links[2].url = promotionUrl

      if (phoneStore.isLogin) {
        await createWebsiteVisit({
          id: store.brandId
        })

        await createSlugProspect({
          slug: store.slugUrl
        })
      }
    }
  }
})
</script>
