<template>
  <div
    v-if="tncOpen"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div class="bg-zinc-800 rounded-lg w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/5 p-4">
      <div class="flex items-center justify-evenly ml-10">
        <h2 class="text-white text-xl font-bold">Terms & Conditions</h2>
        <button class="text-white text-base font-normal focus:outline-none" @click="closeModal">
          ✕
        </button>
      </div>
      <p class="text-stone-400 mt-4" v-html="tnc"></p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useBrandStore } from '@/stores/store.js'

const store = useBrandStore()

const closeModal = () => {
  emit('close-tnc')
}

const props = defineProps({
  tncOpen: {
    type: Boolean,
    required: true
  },
  minSpend: {
    type: Number,
    required: true
  },
  tnc: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close-tnc'])
</script>
