import { createRouter, createWebHistory } from 'vue-router'
import { useBrandStore } from '@/stores/store'
import { usePhoneStore } from '@/stores/store'
import Login from '@/pages/Login/Index.vue'
import MessageLog from '@/pages/MessageLog/Index.vue'
import ViewDetails from '@/pages/ViewDetails/Index.vue'
import CheckPointsRewards from '@/pages/CheckPointsRewards/Index.vue'
import Register from '@/pages/CheckPointsRewards/Register.vue'
import Meta from '@/pages/LoginMeta/Index.vue'
import QualifiedRedemption from '@/pages/QualifiedRedemption/Index.vue'
import History from '@/pages/History/Index.vue'
import Tnc from '@/pages/TermsAndConditions/Tnc.vue'
import LandingSearch from '../pages/LandingSearch/Index.vue'
import DeactivatedWarning from '@/pages/DeactivatedWarning/Index.vue'

import cookie from '@/utils/cookie.js'
import axios from 'axios'

// Google Review
import GoogleReview from '@/pages/GoogleReview/Index.vue'
import Giveaway from '@/pages/Giveaways/Index.vue'
import MetaProfile from '@/pages/ViewDetails/MetaProfile.vue'

//Spin the Wheel
import SpinTheWheel from '@/pages/Wheel/Index.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:slug?',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/check-points-rewards',
      name: 'CheckPointsRewards',
      component: CheckPointsRewards
    },
    {
      path: '/tnc',
      name: 'Tnc',
      component: Tnc
    },
    {
      path: '/deactivated-warning',
      name: 'DeactivatedWarning',
      component: DeactivatedWarning
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: Login },
    {
      path: '/message-log',
      name: 'MessageLog',
      component: MessageLog,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    {
      path: '/history',
      name: 'History',
      component: History,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    {
      path: '/meta',
      name: 'Meta',
      component: Meta,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    {
      path: '/qualified-redemption',
      name: 'QualifiedRedemption',
      component: QualifiedRedemption,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    {
      path: '/view-details',
      name: 'ViewDetails',
      component: ViewDetails,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    // Google Review
    {
      path: '/:slug/google-review/:reviewId',
      name: 'GoogleReview',
      component: GoogleReview,
      meta: {
        requiresActiveBrand: true
      }
    },
    {
      path: '/landing-search',
      name: 'LandingSearch',
      component: LandingSearch
    },
    {
      path: '/:slug/giveaway/:id',
      name: 'Giveaway',
      component: Giveaway,
      meta: {
        requiresActiveBrand: true
      }
    },
    {
      path: '/meta/profile',
      name: 'MetaProfile',
      component: MetaProfile,
      meta: {
        requiresSlug: true,
        requiresActiveBrand: true
      }
    },
    {
      path: '/:slug/spin-to-win/:id',
      name: 'SpinTheWheel',
      component: SpinTheWheel,
      meta: {
        requiresActiveBrand: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const store = useBrandStore()

  if (to.params.slug) {
    cookie.setSecureCookie('slSlug', to.params.slug)
  }

  if (to.meta.requiresActiveBrand) {
    await axios.post(
      process.env.VUE_APP_ENDPOINT + 'add_sl_app_cookie',
      {},
      { withCredentials: true }
    )
  } else {
    await axios.post(
      process.env.VUE_APP_ENDPOINT + 'remove_sl_app_cookie',
      {},
      { withCredentials: true }
    )
  }

  if (to.name !== 'Login') {
    if (to.meta.requiresSlug && !store.slugUrl) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload()
  }
})

export default router
