<template>
  <teleport to="body">
    <ModalBackdrop>
      <div
        class="bg-gray-900 bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
      >
        <div class="relative w-full max-w-md max-h-full m-auto">
          <div class="relative bg-white rounded-lg shadow">
            <ModalBody class="space-y-0 px-3 pt-4 pb-0">
              <p class="text-black font-bold text-2xl flex justify-center text-center">
                Validate your phone number!
              </p>

              <FlexRow class="flex flex-col text-center" style="margin-bottom: 15px">
                <p class="text-base text-gray-500 leading-tight" v-if="props.minSpend !== 0">
                  So that we know that you have spent at least {{ props.currencyCode
                  }}{{ props.minSpend }} &#128524;
                </p>
                <p class="text-gray-500 mt-2">Your reward will be sent to you via SMS</p>
              </FlexRow>

              <FlexCol class="mb-4">
                <FlexRow>
                  <input
                    class="border border-gray-300 text-gray-900 text-md rounded-lg focus:border-blue-600 w-full p-3 focus:ring-0 ring-offset-0 my-5"
                    id="intlPhone"
                    type="tel"
                    v-model="inputVal"
                    :style="{
                      border: validNumber ? '2px solid black' : '2px solid #e0e0e0'
                    }"
                  />
                </FlexRow>
              </FlexCol>

              <div class="w-full">
                <Button
                  variant="outline"
                  size="lg"
                  class="w-3/12 me-5"
                  @click="() => emit('go-back')"
                >
                  <template #icon>
                    <IconArrowLeft class="my-[0.4rem]" />
                  </template>
                </Button>
                <Button
                  :disabled="!validNumber"
                  variant="mulah"
                  size="lg"
                  class="w-8/12 mb-5"
                  @click="handleConfirmClicked"
                >
                  Confirm
                </Button>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'

import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import Timer from '@/pages/GoogleReview/Components/Timer.vue'

import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import { useBrandStore } from '../../stores/store'
import { useRoute, useRouter } from 'vue-router'
import { AsYouType } from 'libphonenumber-js'

const store = useBrandStore()
const selectedCountry = ref(store.countryCode)
const dialCode = ref(null)
const inputVal = ref('')
const validNumber = ref(false)
const intlInputLoading = ref(true)
const inputValueFormatted = ref(null)

onMounted(() => {
  initializePhoneNumberInput()
})

const handleConfirmClicked = () => {
  emit('valid-number-updated', [
    validNumber.value,
    selectedCountry.value,
    inputValueFormatted.value
  ])
}

const initializePhoneNumberInput = async () => {
  const input = document.querySelector('#intlPhone')

  const country = selectedCountry.value

  const iti = intlTelInput(input, {
    initialCountry: country,
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
  })

  const getDialCode = (iti) => {
    dialCode.value = iti.getSelectedCountryData().dialCode
  }

  getDialCode(iti)

  input.addEventListener('countrychange', () => {
    getDialCode(iti)
    selectedCountry.value = iti.getSelectedCountryData().iso2.toUpperCase()
    inputVal.value = ''
  })
  intlInputLoading.value = false
}

watch(inputVal, (newVal) => {
  validNumber.value = isValidPhoneNumber(`+${dialCode.value}${newVal}`)
  const asYouType = new AsYouType(selectedCountry.value)
  asYouType.input(newVal)
  inputValueFormatted.value = asYouType.getNationalNumber()
})
const emit = defineEmits(['go-back', 'valid-number-updated'])

const props = defineProps(['currencyCode', 'minSpend'])
</script>

<style scoped>
li span {
  position: relative;
  left: -2px;
}

ul {
  list-style-position: outside;
}

li {
  margin-left: 16px;
}

:deep(.iti) {
  width: 100%;
}
</style>
