<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
    <path
      stroke="currentColor"
      d="M5.29288 1L1.99999 4.29289C1.60946 4.68342 1.60946 5.31658 1.99999 5.70711L5.29288 9M2.29288 5.00001L16.2929 5.00001"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
