<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <MobileLayout :title="store.brandName">
    <Login v-if="currentPage === 'Login' && !loggedIn" @nextPage="handleNextPage"/>
    <Success v-if="currentPage === 'Success' | loggedIn" />
  </MobileLayout>
</template>

<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import Login from "./Login.vue";
import Success from "./Success.vue";
import { ref, watch } from 'vue';
import { useFbUserDetails } from '@/stores/fbUserDetails.js'
import { useBrandStore } from '@/stores/store';

const loggedIn = ref(false);
const currentPage = ref('Login');

const store = useBrandStore();
const userDetails = useFbUserDetails();

watch(userDetails, () => {
  if (userDetails.id) {
    loggedIn.value = true;
  }
});

const handleNextPage = (val) => {
  currentPage.value = val
}
</script>
