<template>
  <section class="sticky bg-white margin-0">
    <FlexRow class="justify-center w-full items-center relative py-1">
      <FlexCol class="align-middle">
        <h3 class="text-center text-lg font-poppinsBold">
          <span class="text-mulah-red font-bold font-inter">Simple</span
          ><span class="text-primary-black font-bold font-inter">Loyalty</span>
        </h3>
      </FlexCol>
      <div class="absolute right-0">
        <button type="button" class="text-icon-grey focus:outline-none" @click="showSideNav = true">
          <BurgerIcon class="w-6 h-6" />
        </button>
      </div>
    </FlexRow>
  </section>

  <Transition name="slide-searchbar">
    <div v-if="showModal == true">
      <SearchPage></SearchPage>
    </div>
  </Transition>

  <div v-if="showModal == false" style="margin-top: 55%">
    <FlexRow class="justify-end">
      <div class="absolute-right-0 top-0"><img src="@/assets/stripes.svg" /></div>
    </FlexRow>
    <FlexCol class="items-center">
      <FlexRow class="pb-2.5 relative justify-center items-center">
        <h1 class="text-3xl font-poppinsBold text-primary-black text-center">
          Welcome to<br />
          SimpleLoyalty
        </h1>
      </FlexRow>
      <FlexRow class="justify-center items-center">
        <div class="text-sm">Start by searching your store!</div>
      </FlexRow>
      <SearchBar class="w-4/5" @click="toggleSearchPage"></SearchBar>
    </FlexCol>
  </div>
  <div class="min-h-fit min-w-full">
    <slot></slot>
  </div>
  <SideNav :showSideNav="showSideNav" @closeSideNav="showSideNav = false" />
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import SearchBar from '../LandingSearch/SearchBar.vue'
import SearchPage from '../LandingSearch/SearchPage.vue'
import SideNav from '@/layout/MobileLayout/SideNav/SideNav.vue'
import BurgerIcon from '@/components/icons/IconBurger.vue'
import { ref } from 'vue'
const showSideNav = ref(false)

const showModal = ref(false)

function toggleSearchPage() {
  showModal.value = true
}
</script>
<style scoped>
body,
html {
  margin: 0;
  padding: 0;
}

.slide-searchbar-enter-active {
  transition: all 0.5s ease-out;
}

.slide-searchbar-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-searchbar-enter-from,
.slide-searchbar-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
