<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-6 w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4"
    >
      <div class="flex items-center justify-center mb-3">
        <IconSMS class="w-1/6 h-1/6" />
      </div>

      <p class="flex justify-center mb-2">Check your SMS Inbox for</p>
      <h2 class="text-2xl mb-4 flex justify-center font-bold">{{ title }}</h2>
      <div class="flex flex-col">
        <Button
          @click="backToHome"
          class="w-full px-4 py-2 bg-red-500 text-white rounded"
          variant="mulah"
        >
          Back To Home
        </Button>
        <Button
          class="w-full px-4 py-2 bg-white text-black rounded border-none hover:bg-white"
          @click="showPrize"
        >
          Check Out Other Rewards
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useBrandStore } from '@/stores/store.js'

import Button from '@/components/uielements/Button/Button.vue'
import IconSMS from '@/components/icons/IconSMS.vue'

const router = useRouter()
const store = useBrandStore()

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  title: {
    type: String
  },
  message: {
    type: String
  },
  prizeResult: {
    type: [String, null],
    required: true
  },
  sessionId: {
    type: [String, null],
    required: true
  }
})

const emit = defineEmits(['close-modal', 'show-prize'])

const closeModal = () => {
  emit('close-modal')
}

const showPrize = () => {
  emit('show-prize')
}

const backToHome = () => {
  closeModal()
  router.push({ path: '/' + store.slugUrl })
}
</script>
