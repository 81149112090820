<template>
  <FlexCol class="flex items-center justify-center overflow-x-hidden">
    <FlexRow class="w-full">
      <img :src="props.imageUrl" class="w-full h-auto" v-if="props.imageUrl !== null" />
      <div v-else class="relative" style="white-space: nowrap; overflow: hidden">
        <img :src="Voucher" />
        <p
          class="absolute text-left font-bold leading-none"
          :class="{
            'text-lg': promotionNameText.length < 10,
            'text-xs': promotionNameText.length >= 10
          }"
          style="bottom: 43%; right: 89%"
          v-html="promotionNameText"
        ></p>

        <p
          class="absolute text-left font-bold leading-none"
          :class="{
            'text-lg': promotionNameText.length < 10,
            'text-xs': promotionNameText.length >= 10
          }"
          style="bottom: 43%; left: 33%"
          v-html="promotionNameText"
        ></p>
        <p
          class="absolute text-left font-bold leading-none"
          :class="{
            'text-lg': promotionNameText.length < 10,
            'text-xs': promotionNameText.length >= 10
          }"
          style="bottom: 43%; left: 82.7%"
          v-html="promotionNameText"
        ></p>
      </div>
    </FlexRow>
    <FlexRow class="bg-[#1f1f1f] w-full flex justify-center items-center py-3 px-3">
      <img :src="GoogleGLogo" style="width: 20px; height: 20px" class="me-2" />

      <p class="text-md text-white" style="z-index: 2">
        Leave a review & get {{ props.promotionName }}!
      </p>
    </FlexRow>
  </FlexCol>
</template>

<script setup>
import { computed } from 'vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import GoogleGLogo from '@/assets/png/google-g-logo.png'
import Voucher from '@/assets/png/voucher-for-app.png'

const props = defineProps({
  promotionName: {
    type: String,
    required: true
  },
  imageUrl: {
    type: [String, null],
    required: true
  }
})

const promotionNameText = computed(() => {
  let formattedText = ''
  if (props.promotionName.includes('Cash Rebate') || props.promotionName.includes('Discount')) {
    formattedText = props.promotionName.replace('Cash Rebate', '').replace('Discount', '') + 'OFF'
  } else {
    formattedText = props.promotionName
  }

  if (formattedText.length > 12) {
    const substrings = []
    for (let i = 0; i < formattedText.length; i += 12) {
      substrings.push(formattedText.slice(i, i + 12))
    }
    formattedText = substrings.join('<br>')
  }

  return formattedText
})
</script>
