<template>
  <Card class="hover:bg-inherit pt-1">
    <AccordianCollapse :accordian-id="'accordion-' + messageId">
      <AccordianHeading
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2.5 justify-between"
      >
        <span class="text-primary-black font-poppinsBold text-sm">{{ getFullDatetime[0] }}</span>
      </AccordianHeading>
      <Card class="shadow-none hover:bg-inherit w-64">
        <FlexRow class="gap-x-4 justify-between">
          <div class="items-center">
            <div v-if="messageObj.type === 'Expiry'">
              <p class="text-start font-inter text-xs text-primary-black">Credit</p>
              <p class="font-inter text-xs text-primary-black mt-2">+{{ messageObj.amount }}</p>
            </div>
            <div v-else-if="messageObj.type === 'Items Redeemed'">
              <p class="text-start font-inter text-xs text-primary-black">Redemption</p>
              <p class="font-inter text-xs text-primary-black mt-2">-{{ messageObj.amount }}</p>
            </div>
            <div v-else-if="messageObj.type === 'Voided'">
              <p class="text-start font-inter text-xs text-mulah-red">Voided</p>
              <p class="font-inter text-xs text-primary-black mt-2">Points Voided</p>
            </div>
          </div>
          <div class="gap-x-2 items-center">
            <p class="text-start font-inter text-xs text-primary-black">Balance Points</p>
            <p class="font-inter text-xs text-primary-black mt-2">
              {{ messageObj.balance }}
            </p>
          </div>
        </FlexRow>
      </Card>
      <AccordianBody
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2"
      >
        <p class="text-primary-black font-poppinsBold text-sm mt-1">Details</p>
        <p class="basis-3/4 font-inter text-sm text-primary-black mt-3">
          {{ messageObj.type }}
        </p>
        <p class="basis-4/4 font-inter font-semibold text-sm text-primary-black mt-1">
          {{ messageObj.content }}
        </p>
      </AccordianBody>
    </AccordianCollapse>
  </Card>
</template>
<script setup>
import AccordianCollapse from '@/components/uielements/Accordian/AccordianCollapse.vue'
import AccordianHeading from '@/components/uielements/Accordian/AccordianHeading.vue'
import AccordianBody from '@/components/uielements/Accordian/AccordianBody.vue'
import Card from '@/components/uielements/Card/Card.vue'
import FlexRow from '@/components/layout/FlexRow.vue'

import { computed, defineProps } from 'vue'

const props = defineProps({
  messageId: {
    type: [String, Number],
    required: true
  },
  messageObj: {
    type: Object,
    required: true
  }
})

const getFullDatetime = computed(() => {
  //input format: 2023-09-21T03:11:12Z
  const date = new Date(props.messageObj.datetime)
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  let fullDate = date.toLocaleDateString('en-US', options)
  //output format: Wed, Aug 2, 2023, 9:00 PM
  let weekday = fullDate.split(',')[0]
  let day = fullDate.split(',')[1].split(' ')[2]
  let month = fullDate.split(',')[1].split(' ')[1]
  let year = fullDate.split(',')[2]
  let time = fullDate.split(',')[3]
  return [`${weekday}, ${day} ${month} ${year} at ${time}`, `${day} ${month} ${year}, ${time}`]
})
</script>

<style scoped></style>
