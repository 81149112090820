<template>
  <FlexCol class="w-full">
    <h3 class="font-bold font-inter my-2">Links</h3>
    <a
      v-for="link in links"
      :key="link.title"
      :href="link.url"
      target="_blank"
      @click="createUrlClick(link.specification)"
      :disabled="link.url === '' || link.url === null"
    >
      <div
        class="rounded-full border pt-3.5 pb-3.5 mb-3 flex justify-center"
        style="cursor: pointer"
      >
        <!-- TODO: Will need to add back in the future -->
        <!-- <img class="h-12 w-12 ms-2 me-2 rounded-full bg-gray-50 border" src="@/assets/logo.svg" /> -->
        <!-- <p class="flex items-center justify-center w-9/12"> -->
        <p>
          <span>{{ link.title }}</span>
        </p>
      </div>
    </a>
  </FlexCol>
</template>

<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import { defineProps, ref } from 'vue'
import { useMutation } from '@urql/vue'
import { useBrandStore } from '../../stores/store'
import { CREATE_URL_CLICK } from '@/constants/graphql'

const store = useBrandStore()
const pause = ref(false)
const { executeMutation } = useMutation(CREATE_URL_CLICK)

const props = defineProps({
  links: {
    type: Object,
    required: true
  }
})

const createUrlClick = async (spec) => {
  if (pause.value === false) {
    pause.value = true
    await executeMutation({
      id: store.brandId,
      specification: spec
    })
    pause.value = false
  }
}
</script>

<style scoped>
a[disabled='true'] div {
  background: grey;
  color: white;
  opacity: 0.75;
}
</style>
