C
<template>
  <svg
    width="158"
    height="158"
    viewBox="0 0 158 158"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79 158C122.63 158 158 122.63 158 79C158 35.3695 122.63 0 79 0C35.3695 0 0 35.3695 0 79C0 122.63 35.3695 158 79 158Z"
      fill="currentColor"
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M99.1712 57.5477L70.2407 87.3194L60.279 77.0697L53.6331 83.9094L70.2732 101.034L105.817 64.3868L99.1712 57.5477Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.8421 147.684C116.863 147.684 147.684 116.863 147.684 78.8421C147.684 40.8217 116.863 10 78.8421 10C40.8217 10 10 40.8217 10 78.8421C10 116.863 40.8217 147.684 78.8421 147.684Z"
      fill="currentColor"
    />
    <mask
      id="mask0_901_2001"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="58"
      y="58"
      width="42"
      height="42"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.75 64.375L64.375 58.75L79 73.375L93.625 58.75L99.25 64.375L84.625 79L99.25 93.625L93.625 99.25L79 84.625L64.375 99.25L58.75 93.625L73.375 79L58.75 64.375Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_901_2001)">
      <path d="M52 52H106V106H52V52Z" fill="white" />
    </g>
  </svg>
</template>
