<template>
  <div class="flex flex-row items-center justify-between mt-2 relative">
    <div class="w-full">
      <FormInput
        v-model="modelValue"
        class="rounded-md bg-gray-200"
        style="font-size: 0.875rem !important"
        placeholder="Search store"
        maxlength="25"
        readonly="readonly"
      />
    </div>
    <div class="p-2 absolute right-2">
      <IconSearch class="w-4 h-4 text-primary-black cursor-pointer" />
    </div>
  </div>
</template>
<script setup>
import FormInput from '@/components/uielements/FormInput/FormInput.vue'
import IconSearch from '@/components/icons/IconSearch.vue'

import { ref, watch } from 'vue'
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  clearField: {
    type: Function
  }
})

const emit = defineEmits(['update:modelValue'])
const modelValue = ref(props.modelValue)

watch(modelValue, (value) => {
  emit('update:modelValue', value)
})
</script>
