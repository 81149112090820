<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <MobileLayout :title="store.brandName">
    <FlexCol class="container min-h-[75vh] mt-6 mb-12 overflow-y-auto">
      <FlexCol class="justify-between" style="min-height: inherit">
        <FlexRow>
          <FlexCol class="w-full">
            <h1 class="font-poppinsBold text-3xl text-primary-black mb-2">
              Member&rsquo;s Details
            </h1>
            <FlexCol class="my-2 h-[5.2rem]">
              <label class="mb-2 text-sm font-medium text-gray-900">Name</label>
              <div class="relative">
                <FormInput v-model="name" :placeholder="oldName" :disabled="!editingName" />
                <button
                  @click="() => toggleEdit('name')"
                  class="absolute right-2 my-3"
                  v-if="!editingName"
                >
                  <img
                    style="width: 20px; height: 20px"
                    src="https://www.svgrepo.com/show/36160/edit-button.svg"
                    alt="Pencil Icon"
                  />
                </button>
                <button @click="cancelEditName" class="absolute right-2 my-3" v-if="editingName">
                  <img
                    style="width: 20px; height: 20px"
                    src="https://www.svgrepo.com/show/23063/cancel.svg"
                    alt="Cancel Icon"
                  />
                </button>
              </div>
              <span v-if="!validName && editingName" class="text-xs font-medium text-mulah-red">
                *Please choose a different name
              </span>
            </FlexCol>
            <FlexCol class="my-2 h-[5.2rem]">
              <label class="mb-2 text-sm font-medium text-gray-900">Phone number</label>
              <FormInput :placeholder="phoneNumber" disabled />
            </FlexCol>
            <FlexCol class="my-2 h-[5.2rem]">
              <label class="mb-2 text-sm font-medium text-gray-900">Birthday</label>
              <FormInput :placeholder="birthdate" disabled />
            </FlexCol>
            <FlexCol class="my-2 h-[5.2rem]">
              <label class="mb-2 text-sm font-medium text-gray-900">Email</label>
              <div class="relative">
                <FormInput v-model="email" :placeholder="oldEmail" :disabled="!editingEmail" />
                <button
                  @click="() => toggleEdit('email')"
                  class="absolute right-2 my-3"
                  v-if="!editingEmail"
                >
                  <img
                    style="width: 20px; height: 20px"
                    src="https://www.svgrepo.com/show/36160/edit-button.svg"
                    alt="Pencil Icon"
                  />
                </button>
                <button @click="cancelEditEmail" class="absolute right-2 my-3" v-if="editingEmail">
                  <img
                    style="width: 20px; height: 20px"
                    src="https://www.svgrepo.com/show/23063/cancel.svg"
                    alt="Cancel Icon"
                  />
                </button>
              </div>
              <span v-if="!validEmail && editingEmail" class="text-xs font-medium text-mulah-red">
                *Please choose a different email</span
              >
            </FlexCol>
            <FlexRow class="items-start gap-x-2 mt-3 mb-3">
              <span>
                <input
                  v-model="mktgSubscribed"
                  type="checkbox"
                  class="w-5 h-5 text-black bg-gray-100 border-gray-300 rounded-full focus:ring-0 ring-0 focus:ring-offset-0"
                />
              </span>
              <span class="text-sm flex self-center">Subscribe to marketing messages</span>
            </FlexRow>
            <MetaProfile />
          </FlexCol>
        </FlexRow>
        <FlexRow class="mt-2 mb-6">
          <FlexCol class="w-full">
            <Button variant="mulah" size="lg" :disabled="!valid" @click="updateDetails()">
              Update
            </Button>
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </FlexCol>
    <template v-if="showModal">
      <UpdatedModal @close-modal="showModal = false" />
    </template>
    <template #footer>
      <MobileFooter />
    </template>
  </MobileLayout>
</template>
<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FormInput from '@/components/uielements/FormInput/FormInput.vue'
import UpdatedModal from './UpdateModal.vue'
import MetaProfile from './MetaProfile.vue'
import { useQuery } from '@urql/vue'
import { useBrandStore } from '../../stores/store'
import { usePhoneStore } from '../../stores/store'
import { useMutation } from '@urql/vue'
import { computed, ref, watch } from 'vue'
import { UPDATE_CUSTOMER_NAME_AND_EMAIL } from '@/constants/graphql.js'
import { UPDATE_CUSTOMER_BLACKLIST } from '@/constants/graphql'
import { GET_CUSTOMER_INFO } from '@/constants/graphql'

const store = useBrandStore()
const phoneStore = usePhoneStore()
const name = ref('')
const email = ref('')
const birthdate = ref()
const oldName = ref(null)
const oldEmail = ref(null)
const mktgSubscribed = ref(false)
const oldMktgSubscribed = ref(false)
const pause = ref(false)
const showModal = ref(false)
const { executeMutation: updateCustomerNameAndEmail } = useMutation(UPDATE_CUSTOMER_NAME_AND_EMAIL)
const { executeMutation: updateCustomerBlackList } = useMutation(UPDATE_CUSTOMER_BLACKLIST)

const info = useQuery({
  query: GET_CUSTOMER_INFO,
  variables: {
    slug: store.slugUrl
  },
  pause: pause
})

watch(info.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    oldName.value = info.data.value.slAppCustomer.name
    oldEmail.value = info.data.value.slAppCustomer.email
    birthdate.value =
      info.data.value.slAppCustomer.birthdate !== null
        ? info.data.value.slAppCustomer.birthdate.split('T')[0]
        : 'Not Found'
    mktgSubscribed.value = !info.data.value.slAppCustomer.blacklist
    oldMktgSubscribed.value = mktgSubscribed.value
  }
})

const updateDetails = async () => {
  if (valid && pause.value === false) {
    pause.value === true

    const updateCustomer = await updateCustomerNameAndEmail({
      name: name.value ? name.value : '-',
      email: email.value ? email.value : '-'
    })

    const updateBlacklist = await updateCustomerBlackList({
      brandId: store.brandId,
      blacklisted: !mktgSubscribed.value
    })

    if (updateCustomer.error || updateBlacklist.error) {
      return alert('Failed to update')
    } else {
      info.executeQuery({
        requestPolicy: 'network-only'
      })

      pause.value === false
      showModal.value = true
    }
  }
}

const editingName = ref(false)
const editingEmail = ref(false)

const toggleEdit = (field) => {
  if (field === 'name') {
    editingName.value = !editingName.value
    if (editingName.value) {
      name.value = oldName.value
    }
  } else if (field === 'email') {
    editingEmail.value = !editingEmail.value
    if (editingEmail.value) {
      email.value = oldEmail.value
    }
  }
}

const cancelEditName = () => {
  editingName.value = false
  name.value = ''
}

const cancelEditEmail = () => {
  editingEmail.value = false
  email.value = ''
}

const valid = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  if (name.value && email.value) {
    return emailRegex.test(email.value) && validName.value && validEmail.value
  } else if (name.value) {
    return validName.value
  } else if (email.value) {
    return emailRegex.test(email.value) && validEmail.value
  } else return mktgSubscribedChanged.value
})

const validName = computed(() => {
  if (name.value.trim() !== oldName.value) {
    return true
  } else false
})

const validEmail = computed(() => {
  const trimEmail = (email.value = email.value ? email.value.trim() : '')

  if (trimEmail !== oldEmail.value) {
    return true
  } else false
})

const mktgSubscribedChanged = computed(() => {
  if (mktgSubscribed.value !== oldMktgSubscribed.value) {
    return true
  } else {
    return false
  }
})

const phoneNumber = computed(() => {
  const phoneNum = phoneStore.trunkPrefix + phoneStore.phoneNumber

  if (phoneNum !== '' && phoneNum !== null) {
    return phoneNum
  } else {
    return phoneStore.phoneNumberWithPrefix
  }
})
</script>
