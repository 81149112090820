<template>
  <FlexRow class="justify-center">
    <FlexCol class="items-center container min-h-[30rem]">
      <div class="text-mulah-red mt-16 mb-4">
        <IconFrown class="w-20 h-20" />
      </div>
      <h2 class="font-poppinsBold text-[#2f2f2f] text-3xl mb-1 text-center">Whoops!</h2>
      <h6 class="text-icon-grey text-lg text-center mt-6">
        You won a {{ props.rewardName }} voucher, but we can't send it right now as we're out of
        credits.
        <br />
        <br />
        No worries though, your voucher is queued up to be sent as soon as possible!
      </h6>
    </FlexCol>
  </FlexRow>
  <FlexRow>
    <FlexCol class="mt-5 w-full justify-center">
      <Button
        class="hover:bg-white hover:text-red-500"
        :variant="'mulah'"
        size="lg"
        @click="goToHome"
        >Back to Home</Button
      >
    </FlexCol>
  </FlexRow>
</template>

<script setup>
import IconFrown from '@/components/icons/IconFrown.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import Button from '@/components/uielements/Button/Button.vue'
import { useRouter } from 'vue-router'
import { useBrandStore } from '@/stores/store.js'

const router = useRouter()
const store = useBrandStore()

function goToHome() {
  router.push({ path: '/' + store.slugUrl })
}

const props = defineProps({
  rewardName: {
    type: Boolean,
    required: true
  }
})
</script>
