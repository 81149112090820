<template>
  <div class="text-center">
    <div class="flex flex-col items-center" style="padding: 1rem">
      <img
        src="@/assets/images/img_lock_reminder.webp"
        class="card-img-top"
        style="width: 40%; height: 40%"
      />
      <div class="card-body flex flex-col items-center content-center">
        <h5>Whoops!</h5>
        <p class="mb-0">
          Your brand, <strong>{{ brandName }}</strong
          >, has been deactivated.
        </p>
        <p>Do contact your client success manager to unlock your account</p>
        <button class="btn btn-mulah w-full px-5 py-3 mt-3" @click="chooseBrand">Back</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useBrandStore } from '@/stores/store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
const store = useBrandStore()
const router = useRouter()
import cookie from '@/utils/cookie.js'

const brandName = computed(() => {
  return store.brandName
})

function chooseBrand() {
  cookie.removeSecureCookie('slSlug')
  store.$reset()
  router.push({ name: 'Login' })
}
</script>
<style scoped>
.jumbotron {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
