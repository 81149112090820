<template>
  <div
    v-if="spendOpen"
    class="fixed inset-x-0 top-20 bottom-0 h-4/5 flex items-center justify-center bg-black bg-opacity-0 z-50"
  >
    <div class="bg-zinc-900 w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/5 p-4">
      <h2 class="text-2xl mb-4 flex justify-center font-bold text-white">Spend & Win.</h2>
      <p class="text-stone-400 text-center">
        Spend, collect points and stand a chance to win prizes when you spin the wheel!
      </p>
      <div
        class="w-full h-auto bg-zinc-800 rounded-md flex flex-col items-center mt-10"
        style="border: 1px dotted white"
      >
        <SadSvg class="mt-4" style="height: 50px; width: 50px; min-height: 50px; min-width: 50px" />
        <p class="mt-4 text-xl font-extrabold text-white">We are sorry.</p>
        <p class="mt-2 text-white">You did not win a prize.</p>
        <p class="mt-2 text-white">Try again next time!</p>
        <p class="mt-2 text-white">This event is valid until</p>
        <p class="mt-2 mb-4 text-white">{{ endDate }}</p>
      </div>
      <Button
        class="text-white w-full mt-10 rounded-lg h-12"
        variant="mulah"
        size="lg"
        @click="backToHome"
        >Back To Home</Button
      >
      <button class="text-red-600 bg-black w-full mt-2 mb-4 rounded-lg h-12" @click="showPrizeList">
        See Prizes
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useBrandStore } from '@/stores/store.js'

import SadSvg from '@/components/icons/IconSadSvg.vue'
import Button from '@/components/uielements/Button/Button.vue'

const router = useRouter()
const store = useBrandStore()

const backToHome = () => {
  router.push({ path: '/' + store.slugUrl })
}

const showPrizeList = () => {
  emit('show-prizes')
}

const props = defineProps({
  spendOpen: {
    type: Boolean,
    required: true
  },
  endDate: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['back-to-home', 'show-prizes'])
</script>
