<template>
  <div
    class="rounded-full overflow-hidden w-circle h-circle flex items-center justify-center border border-gray-300"
  >
    <img
      v-if="!errorOccured && props.imgUrl !== null"
      :src="props.imgUrl"
      @error="handleImageError"
      class="w-full h-full object-cover"
    />
    <div v-if="props.imgUrl == null || errorOccured" class="text-xs">
      <p class="text-center">No image available</p>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const props = defineProps({
  imgUrl: String
})

const errorOccured = ref(false)
function handleImageError() {
  errorOccured.value = true
}
</script>
<style scoped>
.w-circle {
  width: 90px;
}

.h-circle {
  height: 90px;
}
</style>
