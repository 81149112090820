<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <FlexRow class="justify-center">
    <h1 class="font-poppinsBold text-3xl text-primary-black mb-3 ms-3">SimpleLoyalty</h1>
    <span class="mt-0.5 text-gray-400">
      <IconCircleSuccess class="w-8 h-8 ms-2" />
    </span>
  </FlexRow>
  <FlexRow
    class="justify-center mb-9 font-normal text-base text-icon-grey leading-tight text-center"
  >
    <p>Verify your identity to lift your account restrictions!</p>
  </FlexRow>
  <FlexRow class="justify-center mb-9">
    <FlexCol class="w-full gap-2.5 rounded-2">
      <FacebookLogin
        :app-id="1823210014765609"
        :on-success="handleLogin"
        :on-failure="handleLoginFailed"
      >
      </FacebookLogin>
    </FlexCol>
  </FlexRow>
  <FlexCol class="ms-3">
    <FlexRow class="mb-3 mx-9">
      <span class="mx-2 mt-0.5 text-gray-400">
        <IconCircleSuccess class="w-5 h-5" />
      </span>
      <h3 class="text-md">Redeem more rewards every month!</h3>
    </FlexRow>
    <FlexRow class="mb-3 mx-9">
      <span class="mx-2 mt-0.5 text-gray-400">
        <IconCircleSuccess class="w-5 h-5 animation-delay-1" />
      </span>
      <h3 class="text-md">Increase your maximum points!</h3>
    </FlexRow>
    <FlexRow class="mb-3 mx-9">
      <span class="mx-2 mt-0.5 text-gray-400">
        <IconCircleSuccess class="w-5 h-5 animation-delay-2" />
      </span>
      <h3 class="text-md">Get more rewards!</h3>
    </FlexRow>
    <FlexRow class="justify-center mt-12 mx-9">
      <span class="fs-5 fw-semibold m-0" style="color: #64748b" @click="skipFbLogin">
        Back
      </span>
    </FlexRow>
  </FlexCol>
</template>

<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconCircleSuccess from '@/components/icons/IconCircleSuccess.vue'
import FacebookLogin from '@/components/reusable/FacebookLogin.vue'
import { useQuery, useMutation } from '@urql/vue'
import { ref, watch } from 'vue'
import { useFbUserDetails } from '@/stores/fbUserDetails.js'
import { usePhoneStore } from '../../stores/store'
import { GET_CUSTOMER_INFO, UPDATE_CUSTOMER_NAME_EMAIL_META } from '@/constants/graphql'
import axios from 'axios'
import { useRouter } from 'vue-router'

const name = ref('')
const email = ref('')
const loginButtonClicked = ref(false)

const router = useRouter()

const store = useFbUserDetails()
const phoneStore = usePhoneStore()
const { executeMutation: updateCustomerDetails } = useMutation(UPDATE_CUSTOMER_NAME_EMAIL_META)

const customer = useQuery({
  query: GET_CUSTOMER_INFO
})

function handleLogin(response) {
  let token = response.authResponse.accessToken;
  let fbUrl = `https://graph.facebook.com/v18.0/me?fields=id,name,email&access_token=${token}`;
  axios({
    method: "GET",
    url: fbUrl,
  })
    .then((result) => {
      loginButtonClicked.value = true
      var email = 'email' in result.data ? result.data.email : "-"
      if (store.id === null) {
        store.$patch({
          id: result.data.id,
          name: result.data.name,
          email: email
        })
      }
      
      updateCustomerDetails({
        name: result.data.name,
        email: email,
        metaVerified: true
      })

      phoneStore.$patch({
        metaVerified: true
      })
    })
    .catch((error) => {
      alert("An error occured when attempting to login with Facebook.")
    });
}

function handleLoginFailed() {
  console.log('User Cancelled or Aborted Login.')
}

function skipFbLogin() {
  router.push({ name: 'QualifiedRedemption' });
}

watch(customer.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (customer.data && customer.data.value.slAppCustomer) {
      name.value = customer.data.value.slAppCustomer.name
      email.value = customer.data.value.slAppCustomer.email
    }
  }
})
</script>

<style scoped>
.fb-button {
  display: flex;
  color: white;
  font-size: 18px;
  background-color: #1967d2;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  width: 100%;
}

.fb-button:hover {
  background-color: #1656b5;
}
</style>
