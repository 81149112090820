<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1L1 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M17 7L9 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path
      d="M17 12.7422L9 12.7422"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
