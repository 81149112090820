/* eslint-disable */
export const currencySymbol = {
    "MY": "RM",
    "ID": "Rp",
    "SG": "SGD",
}

// From current Mulah dashboard
export function currencyConvert(amount, cc) {
    if (cc == 'MY') {
      return parseFloat(amount).toFixed(2)
    } else if (cc == 'ID') {
      return `${Math.round(parseFloat(amount))}k`
    }
  }
  
  export function currencyConvertWCountry(amount, cc) {
    if (cc == 'MY') {
      return 'RM' + parseFloat(amount).toFixed(2)
    } else if (cc == 'ID') {
      return `RP${Math.round(parseFloat(amount))}k`
    } else {
      return `${currency(cc) }${parseFloat(amount).toFixed(2)}`
    }
  }
  
  export function currency(cc) {
    return currencySymbol[cc] || cc
  }
  
