<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable no-unused-vars -->
<template>
  <FlexCol class="container min-h-[40vh]">
    <FlexRow class="justify-center">
      <h1 class="font-poppinsBold text-3xl text-primary-black mb-3 ms-3">
        SimpleLoyalty
      </h1>
      <span class="mt-0.5 text-mulah-red">
        <IconCircleSuccess class="w-8 h-8 ms-2"/>
      </span>
    </FlexRow>
    <FlexRow class="justify-center mb-9 font-normal text-base text-icon-grey leading-tight text-center">
        <p>
          Congratulations! <br />
          Enjoy the best rewards in town now!
        </p>
    </FlexRow>
    <FlexRow class="justify-center mb-9">
      <FlexCol class="w-full gap-2.5 rounded-2">
        <Button 
          variant="mulah" 
          class="font-bold text-xl"
          size="lg" 
          @click="checkRewards"
        > 
          Check Rewards Now 
        </Button>
      </FlexCol>
    </FlexRow>
    <FlexCol class="ms-9">
      <FlexRow class="mb-3 mx-9">
        <span class="mx-2 mt-0.5 text-mulah-red">
          <IconCircleSuccess class="w-5 h-5"/>
        </span>
        <h3 class="text-md">More rewards every month!</h3>
      </FlexRow>
      <FlexRow class="mb-3 mx-9">
        <span class="mx-2 mt-0.5 text-mulah-red">
          <IconCircleSuccess class="w-5 h-5 animation-delay-1"/>
        </span>
        <h3 class="text-md">Increased maximum points!</h3>
      </FlexRow>
    </FlexCol>
  </FlexCol>
</template>

<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconCircleSuccess from '@/components/icons/IconCircleSuccess.vue'
import Button from '@/components/uielements/Button/Button.vue'
import { useRouter } from 'vue-router'

const router = useRouter();

const checkRewards = async () => {
  router.push({ name: 'QualifiedRedemption' });
}
</script>
