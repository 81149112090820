<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="white"
    class="bi bi-person-fill"
    viewBox="-8 -8 32 32"
  >
    <circle cx="8" cy="8" r="10" fill="#FF0000" />
    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
  </svg>
</template>
