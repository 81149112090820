<template>
  <FlexCol class="mt-2">
    <div class="card">
      <div class="card-body">
        <template v-if="instaUserId && instaUserName">
          <h6 class="mb-2" style="font-weight: 700; margin-left: -5px">
            Instagram Profile Details
          </h6>
          <FlexRow>ID: {{ instaUserId }}</FlexRow>
          <FlexRow>UserName: {{ instaUserName }}</FlexRow>
        </template>
        <template v-else>
          <div class="flex flex-col" style="align-items: center">
            <h6 style="text-align: center; font-size: 0.75rem">
              Link your SimpleLoyalty Account with your Instagram Account for future rewards
            </h6>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
              style="width: 60px; height: 60px"
            />
            <Button class="mt-3" variant="outline-secondary" @click="verifyInstaAcc()">
              <span v-if="token">Verify Instagram Account</span>
              <span v-else>Link Instagram Account</span>
            </Button>
          </div>
        </template>
      </div>
    </div>
  </FlexCol>
</template>

<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import { ref, watch, onMounted } from 'vue'
import { useBrandStore } from '../../stores/store'
import { useQuery, useSubscription } from '@urql/vue'
import { SUBSCRIBE_SL_APP_CHANNEL, GET_INSTA_USER_INFO } from '@/constants/graphql.js'

onMounted(() => {
  token.value =
    localStorage.getItem('instaUserToken') == 'null' ? null : localStorage.getItem('instaUserToken')
  if (token.value) {
    pauseGetUsername.value = false
  }
})

const pauseGetUsername = ref(true)
const token = ref(null)
const instaUserName = ref(null)
const instaUserId = ref(null)

const store = useBrandStore()
const handleSubscription = (first, response) => {
  if (response.customerInstaToken.token) {
    token.value = response.customerInstaToken.token
    localStorage.setItem('instaUserToken', response.customerInstaToken.token)
  }
}
const result = useSubscription({ query: SUBSCRIBE_SL_APP_CHANNEL }, handleSubscription)

const instaUserInfo = useQuery({
  query: GET_INSTA_USER_INFO,
  variables: {
    accessToken: token
  },
  pause: pauseGetUsername
})

const openWindow = (link) => {
  window.open(link, 'popUp', 'width=750,height=700')
}

const verifyInstaAcc = () => {
  if (token.value) {
    pauseGetUsername.value = false
  } else {
    alert('Please login first')
    openWindow(`${process.env.VUE_APP_ENDPOINT}facebook_oauth/customer/authenticate`)
  }
}

watch(instaUserInfo.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (
      instaUserInfo.data.value.slAppCustomer &&
      instaUserInfo.data.value.slAppCustomer.instaUserInfo
    ) {
      const data = instaUserInfo.data.value.slAppCustomer.instaUserInfo

      instaUserId.value = data.id
      instaUserName.value = data.username
    } else {
      token.value = null
      localStorage.removeItem('instaUserToken')
    }
  }
})
</script>

<style scoped>
* {
  /* outline: 1px dashed red; */
}
</style>
