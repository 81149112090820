<template>
  <Card class="hover:bg-inherit pt-1">
    <AccordianCollapse :accordian-id="'accordion-' + messageId">
      <AccordianHeading
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2.5 justify-between"
      >
        <span class="text-primary-black font-poppinsBold text-sm">{{ getFullDatetime[0] }}</span>
      </AccordianHeading>
      <AccordianBody
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2"
      >
        <Card class="shadow-none hover:bg-inherit">
          <FlexCol class="gap-y-1.5">
            <FlexRow class="gap-x-2 items-center">
              <p class="basis-1/4 text-end font-inter text-xs text-secondary-grey">From:</p>
              <p class="basis-3/4 font-inter text-xs text-primary-black">
                {{ messageObj.sender }}
              </p>
            </FlexRow>
            <FlexRow class="gap-x-2 items-center">
              <p class="basis-1/4 text-end font-inter text-xs text-secondary-grey">Date:</p>
              <p class="basis-3/4 font-inter text-xs text-primary-black">
                {{ getFullDatetime[1] }}
              </p>
            </FlexRow>
            <!-- TODO: Add in future, hide this for now as our DB does not store campaigns -->
            <!-- <FlexRow class="gap-x-2 items-start">
              <p class="basis-1/4 text-end font-inter text-xs text-secondary-grey">Campaign:</p>
              <p class="basis-3/4 font-inter text-xs text-primary-black">
                {{ messageObj.campaign }} &lt;Personal Message&gt;
              </p>
            </FlexRow> -->
            <FlexRow class="gap-x-2 items-center">
              <p class="basis-1/4 text-end font-inter text-xs text-secondary-grey">Type:</p>
              <p class="basis-3/4 font-inter text-xs text-primary-black">
                {{
                  messageObj.channel === 'whatsapp'
                    ? messageObj.channel.charAt(0).toUpperCase() + messageObj.channel.slice(1)
                    : 'SMS'
                }}
              </p>
            </FlexRow>
          </FlexCol>
        </Card>
      </AccordianBody>
    </AccordianCollapse>
    <p class="px-1 text-primary-black text-xs my-2">
      {{ messageObj.message }}
    </p>
    <FlexRow class="px-1 mt-2 items-center justify-end">
      <span class="text-primary-black text-xs font-inter">Sent on</span>
      <template v-if="messageObj.channel === 'whatsapp'">
        <span class="ms-1 text-[#0EC143]"><IconWhatsapp /></span>
      </template>
      <template v-else>
        <span class="text-mulah-red font-inter text-xs font-semibold ms-1">SMS</span>
      </template>
    </FlexRow>
  </Card>
</template>
<script setup>
import AccordianCollapse from '@/components/uielements/Accordian/AccordianCollapse.vue'
import AccordianHeading from '@/components/uielements/Accordian/AccordianHeading.vue'
import AccordianBody from '@/components/uielements/Accordian/AccordianBody.vue'
import Card from '@/components/uielements/Card/Card.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue'

import { computed, defineProps } from 'vue'

const props = defineProps({
  messageId: {
    type: [String, Number],
    required: true
  },
  messageObj: {
    type: Object,
    required: true
  }
})

const getFullDatetime = computed(() => {
  // Example input format: 2023-09-21T03:11:12Z
  const date = new Date(props.messageObj.datetime)
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  let fullDate = date.toLocaleDateString('en-US', options)
  // Example output format: Wed, Aug 2, 2023, 9:00 PM
  let weekday = fullDate.split(',')[0]
  let day = fullDate.split(',')[1].split(' ')[2]
  let month = fullDate.split(',')[1].split(' ')[1]
  let year = fullDate.split(',')[2]
  let time = fullDate.split(',')[3]
  return [`${weekday}, ${day} ${month} ${year} at ${time}`, `${day} ${month} ${year}, ${time}`]
})
</script>
