<template>
  <template v-if="giveaway.fetching.value || store.brandQueryFetching || !giveawayData">
    <FlexRow class="justify-center">
      <FlexCol class="grid h-screen place-items-center">
        <Spinner :width="16" :height="16" />
      </FlexCol>
    </FlexRow>
  </template>
  <template v-else>
    <MobileLayout :title="store.brandName">
      <InsuffCreditPage v-if="showInsuffCreditsPage" />
      <NoLoginPage
        v-else-if="showNoLoginPage"
        :giveaway-organizer-id="route.params.id"
        :insta-username="userName"
        :giveaway-img="giveawayData.rewardImageUrl"
        @not-enough-spent="handleNotEnoughSpent"
        @go-back="showNoLoginPage = false"
        @success="showSuccessModal = true"
      />
      <template v-else>
        <FlexCol class="mt-2">
          <FlexRow
            ><img
              :src="
                giveawayData.rewardImageUrl
                  ? giveawayData.rewardImageUrl
                  : require('@/assets/images/quest/giveaway_default_pic.png')
              "
              class="h-auto max-w-full max-h-72 m-auto"
            />
          </FlexRow>
        </FlexCol>
        <FlexCol>
          <label class="font-bold text-xl py-5">{{ giveawayData.campaign.title }}</label>
          <label class="text-[#717171] pb-1">Just follow these steps: </label>
        </FlexCol>
        <FlexCol class="mb-4">
          <FlexRow v-for="(task, index) in tasks" :key="index" class="py-4">
            <label class="number-bg me-2.5 p-2">{{ index + 1 }}</label>
            <div>
              <span class="font-medium">{{ task.title }}</span>
              <p class="text-[#717171] text-sm" v-if="index === currentBtnVisible">
                <a
                  v-if="task.task !== 'spendMin'"
                  class="underline cursor-pointer text-[#ff0000]"
                  @click="task.popUp()"
                  >Click Here</a
                >
                {{ task.desc }}
              </p>
            </div>
            <div class="flex grow justify-end max-h-10">
              <CheckGreen v-if="task.iconVisible" />
              <DotFlashing v-else-if="task.loading && index === currentBtnVisible" />
              <Button
                v-else-if="
                  (index === currentBtnVisible && isLogin) ||
                  (index === currentBtnVisible && task.task !== 'spendMin' && !isLogin)
                "
                variant="outline-secondary"
                @click="task.verifyAction(task.task)"
                >Verify</Button
              >
            </div>
          </FlexRow>
        </FlexCol>
        <!-- <FlexRow class="text-[#ff0000] underline cursor-pointer">*Terms & Conditions Apply.</FlexRow> -->
        <FlexRow class="mb-20 mt-4 justify-between">
          <Button
            class="w-2/12 me-2"
            variant="outline-secondary"
            @click="router.push({ path: '/', replace: true })"
            ><BackIcon
          /></Button>
          <Button class="w-10/12" variant="mulah" :disabled="!tasksAllDone" @click="getReward"
            >Get Reward
          </Button>
        </FlexRow>
      </template>
      <template #footer>
        <MobileFooter />
      </template>
    </MobileLayout>
  </template>
  <template v-if="showErrorModal">
    <VerifyErrorModal
      :modal-error-text="modalErrorText"
      @close-modal="() => (showErrorModal = false)"
    />
  </template>
  <template v-if="showSuccessModal">
    <SuccessModal @close-modal="() => (showSuccessModal = false)" />
  </template>
</template>

<script setup>
const getVerifiedInstaUsername = async (task) => {
  currentVerifyAction.value = task

  const args = {
    giveawayOrganizerId: route.params.id,
    instaUsername: userName.value,
    phoneNumber: phoneStore.phoneNumberWithPrefix,
    countryCode: store.countryCode
  }
  try {
    const response = await verifyInstaUsers(args)

    const result = response.data.verifyInstaGiveawayCriteria.result
    const parsedResult = JSON.parse(result)

    const taggedFriend = parsedResult.tagged_friend
    const taggedBusiness = parsedResult.tagged_business
    const reachedSpending = parsedResult.reached_spending

    switch (currentVerifyAction.value) {
      case 'mentionFriend':
        showErrorModal.value = !taggedFriend
        modalErrorText.value = 'Unable to verify tags on the post'
        tagFriend.value = taggedFriend
        break
      case 'tagBusiness':
        showErrorModal.value = !taggedBusiness
        modalErrorText.value =
          'Unable to find your post. Please ensure that your profile is public.'
        tagBusiness.value = taggedBusiness
        break
      case 'spendMin':
        showErrorModal.value = !reachedSpending
        modalErrorText.value = `You will need to spend another ${curSym.value}${minSpend.value} to qualify`
        reachSpending.value = reachedSpending
        break
    }
  } catch (err) {
    console.error(err)
  }
}
import NoLoginPage from '@/pages/Giveaways/NoLoginPage.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import BackIcon from '@/components/icons/IconArrowLeft.vue'
import CheckGreen from '@/components/icons/IconCheckGreen.vue'
import Spinner from '@/components/reusable/Spinner.vue'
import DotFlashing from '@/components/reusable/LoadingThreeDots.vue'
import VerifyErrorModal from './VerifyErrorModal.vue'
import SuccessModal from './SuccessModal.vue'
import InsuffCreditPage from './InsuffCreditPage.vue'
import { currencySymbol } from '@/constants/country_currency.js'
import { useMutation, useQuery, useSubscription } from '@urql/vue'
import { useBrandStore, usePhoneStore } from '../../stores/store'
import { useRoute, useRouter } from 'vue-router'
import {
  SUBSCRIBE_SL_APP_CHANNEL,
  GET_INSTA_USER_INFO,
  GIVEAWAY_CRITERIA,
  GIVEAWAY,
  CLAIM_GIVEAWAY,
  GIVEAWAY_VISIT_TRACKING,
  UPDATE_GIVEAWAY_VISIT_TRACKING,
  VERIFY_INSTA_GIVEAWAY_CRITERIA
} from '@/constants/graphql.js'
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'

const handleSubscription = (_a, response) => {
  if (response.customerInstaToken.token) {
    token.value = response.customerInstaToken.token
    localStorage.setItem('instaUserToken', response.customerInstaToken.token)
  }
}
const result = useSubscription({ query: SUBSCRIBE_SL_APP_CHANNEL }, handleSubscription)

const store = useBrandStore()
const route = useRoute()
const router = useRouter()

const giveawayData = ref(null)
const token = ref(null)
const userName = ref(null)
const minSpend = ref(null)
const tasks = ref(null)
const currentBtnVisible = ref(0)
const tagFriend = ref(false)
const tagBusiness = ref(false)
const reachSpending = ref(false)
const currentVerifyAction = ref(null)
const showErrorModal = ref(false)
const showSuccessModal = ref(false)
const modalErrorText = ref(null)
const pause = ref(false)
const pauseGetUsername = ref(true)
const pauseGetGiveawayCriteria = ref(true)
const showInsuffCreditsPage = ref(false)
const showNoLoginPage = ref(false)

const giveaway = useQuery({
  query: GIVEAWAY,
  variables: {
    organizerId: route.params.id
  }
})

const instaUsername = useQuery({
  query: GET_INSTA_USER_INFO,
  variables: {
    accessToken: token
  },
  pause: pauseGetUsername
})

const { executeMutation: claimGiveaway } = useMutation(CLAIM_GIVEAWAY)

const curSym = computed(() => {
  return currencySymbol[store.countryCode]
})

const tasksAllDone = computed(() => {
  return isLogin
    ? tasks.value.every((t) => Boolean(t.iconVisible))
    : tasks.value.filter((t) => t.task !== 'spendMin').every((t) => Boolean(t.iconVisible))
})

watch(
  () => route.params.id,
  (newVal) => {
    if (newVal) {
      giveaway.executeQuery({ requestPolicy: 'network-only' })
    }
  }
)

watch(giveaway.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (!giveaway.data.value.giveaway) {
      router.push({ path: '/' })
      return
    }

    giveawayData.value = giveaway.data.value.giveaway

    const { friendTagThreshold, shareTagPost, spendingThreshold, instagramUrl } = giveawayData.value
    const instaLoginUrl = `${process.env.VUE_APP_ENDPOINT}facebook_oauth/customer/authenticate`
    minSpend.value = spendingThreshold

    tasks.value = [
      {
        title: 'Verify your Instagram account',
        desc: 'to verify',
        task: null,
        taskExist: friendTagThreshold || shareTagPost,
        iconVisible: computed(() => userName.value),
        verifyAction: () => verifyInstaAcc(),
        popUp: () => openWindow(instaLoginUrl),
        loading: computed(() => instaUsername.fetching.value)
      },
      {
        title: `Comment and tag ${friendTagThreshold} friend(s) in this post`,
        desc: 'and tag your friends in this post verify',
        task: 'mentionFriend',
        taskExist: friendTagThreshold,
        iconVisible: computed(() => tagFriend.value),
        verifyAction: (task) => getVerifiedInstaUsername(task),
        popUp: () => openWindow(instagramUrl)
      },
      {
        title: 'Post about your experience here and tag us in the post',
        desc: 'and tag us using your Instagram Verified account!',
        task: 'tagBusiness',
        taskExist: shareTagPost,
        iconVisible: computed(() => tagBusiness.value),
        verifyAction: (task) => getVerifiedInstaUsername(task),
        popUp: () => openWindow('https://www.instagram.com/')
      },
      {
        title: computed(() => `Spend at least ${curSym.value + spendingThreshold}`),
        desc: computed(() => `Spend a minimum of ${curSym.value + spendingThreshold} at any store`),
        task: 'spendMin',
        taskExist: spendingThreshold,
        iconVisible: computed(() => reachSpending.value),
        verifyAction: (task) => getVerifiedInstaUsername(task)
      }
    ].filter((x) => x.taskExist)
  }
})

watch(instaUsername.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    userName.value = instaUsername.data.value.slAppCustomer.instaUserInfo.username
  }
})

watch(
  tasks,
  (newVal) => {
    if (newVal) {
      if (newVal[currentBtnVisible.value].iconVisible) {
        currentBtnVisible.value++
      }
    }
  },
  { deep: true }
)

const verifyInstaAcc = () => {
  if (token.value) {
    pauseGetUsername.value = false
  } else {
    showErrorModal.value = true
    modalErrorText.value = 'Unable to verify your Instagram account'
  }
}

const openWindow = (link) => {
  window.open(link, 'newWindow', 'width=750,height=700')
}

//not checking for minspend requirement because if not logged in, still need phone number to create customer and send sms to
const getReward = () => {
  if (!isLogin) {
    showNoLoginPage.value = true
  } else {
    handleGetReward(store.countryCode, phoneStore.phoneNumberWithPrefix)
  }
}

const handleGetReward = (countryCode, phoneNumber) => {
  if (!tasksAllDone.value) {
    alert('Please complete all tasks first')
    return
  }

  if (pause.value) return
  pause.value = true

  claimGiveaway({
    giveawayId: route.params.id,
    countryCode: countryCode,
    phoneNumber: phoneNumber
  })
    .then((result) => {
      if (result.data.claimGiveaway.result === 'ERROR') {
        if (result.data.claimGiveaway.errors[0].message === 'Insufficient credits') {
          showInsuffCreditsPage.value = true
          return
        } else {
          throw new Error(result.data.claimGiveaway.errors[0].message)
        }
      }
      showSuccessModal.value = true
      pause.value = false
    })
    .catch((error) => {
      alert(error)
      pause.value = false
    })
}

const handleNotEnoughSpent = () => {
  showNoLoginPage.value = false
  showErrorModal.value = true
  modalErrorText.value = `You will need to spend another ${curSym.value}${minSpend.value} to qualify`
  reachedSpending.value = false
}

// tracking logic
const { executeMutation: beginTracking } = useMutation(GIVEAWAY_VISIT_TRACKING)
const { executeMutation: updateTracking } = useMutation(UPDATE_GIVEAWAY_VISIT_TRACKING)
const { executeMutation: verifyInstaUsers } = useMutation(VERIFY_INSTA_GIVEAWAY_CRITERIA)
const timeTrack = ref()
const phoneStore = usePhoneStore()
const isLogin = phoneStore.isLogin
const currentTrackingId = ref()
onMounted(() => {
  beginTracking({
    organizerId: route.params.id,
    metadata: JSON.stringify({ phoneNumber: phoneStore.phoneNumberWithPrefix })
  })
    .then((result) => {
      timeTrack.value = new Date()
      currentTrackingId.value = result.data.giveawayVisitTracking.trackingId
    })
    .catch((e) => {
      console.log(e)
    })
})

onUnmounted(() => {
  const duration = (new Date() - timeTrack.value) / 1000
  updateTracking({
    trackingId: currentTrackingId.value,
    metadata: JSON.stringify({ durationSec: duration })
  }).catch((e) => {
    console.log(e)
  })
})
</script>

<style scoped>
.number-bg {
  width: 1.6rem;
  height: 1.6rem;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
</style>
