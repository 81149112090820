<template>
  <Card
    :class="{
      'opacity-40': isInactive,
      'h-32': true,
      'w-full': true,
      disabled: isInactive,
      'cursor-pointer': !isInactive
    }"
    style="margin-bottom: 10px"
    @click="handleRedirect"
  >
    <FlexRow class="justify-between">
      <FlexCol class="w-2/5" style="max-width: 100px">
        <CircleImage :imgUrl="props.brand.logoUrl"></CircleImage>
      </FlexCol>
      <FlexCol class="items-center justify-center w-2/5">
        <div v-if="isInactive">
          <p class="text-center">{{ props.brand.name }} (Inactive)</p>
        </div>
        <div v-else>
          <p class="text-center">{{ props.brand.name }}</p>
        </div>
      </FlexCol>
      <FlexCol class="right-2 justify-center">
        <img src="@/assets/card-right-arrow.svg" />
      </FlexCol>
    </FlexRow>
  </Card>
</template>
<script setup>
import Card from '@/components/uielements/Card/Card.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import CircleImage from '../LandingSearch/CircleImage.vue'
import cookie from '@/utils/cookie.js'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  brand: Object
})
const router = useRouter()

const isInactive = ref(!props.brand.active)

function handleRedirect() {
  if (!isInactive.value) {
    router.push(`/${props.brand.slug}`)
    cookie.setSecureCookie('slSlug', props.brand.slug)
  }
}
</script>
