<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
    <path
      d="M11.0001 1.7L6.76441 5.33062C6.31502 5.7158 5.6519 5.7158 5.20251 5.33061L0.966797 1.7"
      stroke="currentColor"
      stroke-width="1.85"
      stroke-linecap="round"
    />
  </svg>
</template>
