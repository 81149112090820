import {
  createClient,
  cacheExchange,
  fetchExchange,
  subscriptionExchange,
  mapExchange
} from '@urql/vue'
import { createConsumer } from '@rails/actioncable'
import SubscriptionExchange from 'graphql-ruby-client/subscriptions/SubscriptionExchange'
import router from './router'
import cookie from '@/utils/cookie'

const actionCable = createConsumer(`${process.env.VUE_APP_WEBSOCKET_ENDPOINT}cable?app=checkpoints`)
const forwardToActionCable = SubscriptionExchange.create({
  consumer: actionCable,
  channelName: 'SlAppChannel'
})

export const client = createClient({
  url: process.env.VUE_APP_BACKEND_URI,
  exchanges: [
    mapExchange({
      onError(error) {
        if (`${error}`.match(/Deactivated/)) {
          router.push({ name: 'DeactivatedWarning' })
        }
      }
    }),
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: forwardToActionCable
    })
  ],
  requestPolicy: 'network-only',
  fetchOptions: () => {
    return {
      headers: {
        app: 'check',
        token: process.env.VUE_APP_TOKEN
      },
      credentials: 'include'
    }
  }
})
