<template>
  <div>
    <p class="text-red-500 font-medium">
      Session will expire in
      <span class="font-semibold"> {{ formattedTime }}</span>
    </p>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import cookie from '@/utils/cookie.js'

const props = defineProps({
  cookieName: {
    type: String,
    default: 'greview-cookie'
  },
  sessionDuration: {
    type: Number,
    default: 900 // 15 minutes in seconds
  }
})

const timer = ref({
  minutes: 0,
  seconds: 0
})

const interval = ref(null)
const emit = defineEmits(['timer-ended'])

onMounted(() => {
  const cookieValue = cookie.getCookie(props.cookieName)

  if (cookieValue) {
    const startTime = new Date(cookieValue).getTime()
    const currentTime = new Date().getTime()
    const elapsedTime = (currentTime - startTime) / 1000
    const remainingTime = props.sessionDuration - elapsedTime

    if (remainingTime > 0) {
      timer.value.minutes = Math.floor(remainingTime / 60)
      timer.value.seconds = Math.floor(remainingTime % 60)
      startTimer()
    } else {
      timer.value.minutes = 0
      timer.value.seconds = 0
      emit('timer-ended')
    }
  } else {
    timer.value.minutes = Math.floor(props.sessionDuration / 60)
    timer.value.seconds = props.sessionDuration % 60
    startTimer()
  }
})

onUnmounted(() => {
  clearInterval(interval.value)
})

function startTimer() {
  interval.value = setInterval(() => {
    if (timer.value.seconds > 0) {
      timer.value.seconds--
    } else if (timer.value.minutes > 0) {
      timer.value.minutes--
      timer.value.seconds = 59
    } else {
      clearInterval(interval.value)
      emit('timer-ended')
    }
  }, 1000)
}

const formattedTime = computed(() => {
  const minutes = timer.value.minutes.toString().padStart(2, '0')
  const seconds = timer.value.seconds.toString().padStart(2, '0')
  return `${minutes}:${seconds}`
})
</script>
