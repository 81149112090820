<template>
  <svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z"
      fill="#FF0000"
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.7136 29.1382L36.0652 44.2124L31.0213 39.0227L27.6562 42.4858L36.0816 51.1566L54.0783 32.601L50.7136 29.1382Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.423 74.7768C59.6739 74.7768 75.2798 59.1708 75.2798 39.92C75.2798 20.6691 59.6739 5.06323 40.423 5.06323C21.1722 5.06323 5.56628 20.6691 5.56628 39.92C5.56628 59.1708 21.1722 74.7768 40.423 74.7768Z"
      fill="#FF0000"
    />
    <path
      d="M51.7 26H29.3C27.76 26 26.514 27.26 26.514 28.8L26.5 54L32.1 48.4H51.7C53.24 48.4 54.5 47.14 54.5 45.6V28.8C54.5 27.26 53.24 26 51.7 26ZM41.9 42.8H39.1V40H41.9V42.8ZM41.9 37.2H39.1V31.6H41.9V37.2Z"
      fill="white"
    />
  </svg>
</template>
