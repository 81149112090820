<template>
  <svg viewBox="0 0 357 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1757_3629)">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H299V125H8C3.58173 125 0 121.418 0 117V8Z"
        fill="white"
      />
      <circle cx="-1" cy="60.5" r="20" fill="#1F1F1F" />
      <!-- Add the slot here -->
      <foreignObject x="0" y="0" width="357" height="125">
        <div xmlns="http://www.w3.org/1999/xhtml">
          <slot></slot>
        </div>
      </foreignObject>
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M299 0H300.96C304.914 0 308.199 2.86983 308.845 6.64062H307.985V10.4118C307.985 10.964 308.433 11.4118 308.985 11.4118C309.537 11.4118 309.985 10.964 309.985 10.4118V6.64062H309.075C309.72 2.86983 313.005 0 316.96 0H349C353.418 0 357 3.58172 357 8V117C357 121.418 353.418 125 349 125H316.96C312.547 125 308.969 121.428 308.96 117.018C308.95 121.428 305.372 125 300.96 125H299V0ZM309.985 17.954C309.985 17.4018 309.537 16.954 308.985 16.954C308.433 16.954 307.985 17.4018 307.985 17.954V25.4963C307.985 26.0486 308.433 26.4963 308.985 26.4963C309.537 26.4963 309.985 26.0486 309.985 25.4963V17.954ZM309.985 33.0386C309.985 32.4863 309.537 32.0386 308.985 32.0386C308.433 32.0386 307.985 32.4863 307.985 33.0386V40.5809C307.985 41.1332 308.433 41.5809 308.985 41.5809C309.537 41.5809 309.985 41.1332 309.985 40.5809V33.0386ZM309.985 48.1232C309.985 47.5709 309.537 47.1232 308.985 47.1232C308.433 47.1232 307.985 47.5709 307.985 48.1232V55.6654C307.985 56.2177 308.433 56.6654 308.985 56.6654C309.537 56.6654 309.985 56.2177 309.985 55.6654V48.1232ZM309.985 63.2077C309.985 62.6554 309.537 62.2077 308.985 62.2077C308.433 62.2077 307.985 62.6554 307.985 63.2077V70.75C307.985 71.3023 308.433 71.75 308.985 71.75C309.537 71.75 309.985 71.3023 309.985 70.75V63.2077ZM309.985 78.2923C309.985 77.74 309.537 77.2923 308.985 77.2923C308.433 77.2923 307.985 77.74 307.985 78.2923V85.8345C307.985 86.3868 308.433 86.8345 308.985 86.8345C309.537 86.8345 309.985 86.3868 309.985 85.8345V78.2923ZM309.985 93.3768C309.985 92.8245 309.537 92.3768 308.985 92.3768C308.433 92.3768 307.985 92.8245 307.985 93.3768V100.919C307.985 101.471 308.433 101.919 308.985 101.919C309.537 101.919 309.985 101.471 309.985 100.919V93.3768ZM309.985 108.461C309.985 107.909 309.537 107.461 308.985 107.461C308.433 107.461 307.985 107.909 307.985 108.461V112.233H309.985V108.461Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_1757_3629">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H299V125H8C3.58173 125 0 121.418 0 117V8Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
